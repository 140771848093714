import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import handleSignIn from "../authUtils";
import { useUserContext } from "../components/UserContext";

const SignUp = ({ isAuthenticated, handleAuthentication }) => {
  const {
    setUserId,
    setUserName,
    setUserAvatar,
    setUserFollows,
    setUserFollowers,
  } = useUserContext();

  const navigate = useNavigate();

  // Navigate to main if user is authenticated
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState([]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Function to get the CSRF token from the cookie
  function getCSRFToken() {
    const name = "csrftoken";
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith(name + "="))
      ?.split("=")[1];
    return cookieValue;
  }

  // Function to handle sign up
  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      // Retrieve referrerId from localStorage if available
      const referrerId = localStorage.getItem("referrerId");

      // Combine form data and referrerId
      const signUpData = {
        ...formData,
        referrer: referrerId ? referrerId : null,
      };

      // Make sign up request with CSRF token
      const response = await axios.post("/api/signup/", signUpData, {
        headers: {
          "X-CSRFToken": getCSRFToken(),
          "Content-Type": "application/json",
        },
      });

      // Automatically sign in this user right away
      handleSignIn(
        formData.username,
        formData.password,
        handleAuthentication,
        setUserId,
        setUserName,
        setUserAvatar,
        setUserFollows,
        setUserFollowers,
        navigate
      );
    } catch (error) {
      // Set errors received from the backend
      setErrors(Object.values(error.response.data));
    }
  };

  return (
    <div className="signup-container">
      <div className="form-section">
        <h2>Sign Up</h2>
        {errors.length > 0 && (
          <div className="error-container">
            <p className="error-message">Errors:</p>
            <ul>
              {errors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          </div>
        )}
        <form onSubmit={handleSignUp} className="signup-form">
          <input
            type="text"
            name="username"
            value={formData.username}
            onChange={handleChange}
            placeholder="Username"
            required
          />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            required
          />
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="Password"
            required
          />
          <button type="submit" className="signup-button">
            Sign Up
          </button>
        </form>
        <p className="terms">
          By signing up, you agree to our{" "}
          <Link to="/terms">Terms of Service</Link>.
        </p>
        <p className="terms">Already have an account?</p>
        <Link to="/signin" className="signin-link">
          Sign In
        </Link>
      </div>
    </div>
  );
};

export default SignUp;
