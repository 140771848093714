import React, { useState, useContext, useEffect } from "react";
import { AxiosContext } from "../../App";
import { logMood } from "./moodActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faFaceLaugh, faFaceSmile, faFaceMeh, faFaceFrown, faFaceSadTear } from "@fortawesome/free-solid-svg-icons";

const MoodPrompt = ({ onClose, handleOpenDoodleEditor }) => {
  const axiosInstance = useContext(AxiosContext);
  const [selectedMood, setSelectedMood] = useState(null);
  const [moodSubmitted, setMoodSubmitted] = useState(false);
  const [showFollowUp, setShowFollowUp] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false); // Track if buttons should be disabled
  const [moodId, setMoodId] = useState(null); // State to store the mood ID

  const handleMoodSelect = async (mood) => {
    if (isDisabled) return; // Prevent additional mood submissions

    setSelectedMood(mood);
    const id = await logMood(axiosInstance, mood); // Log mood and get the mood ID
    setMoodId(id); // Store the mood ID
    setMoodSubmitted(true); // Set to true when mood is submitted

    // Disable further interactions with mood options
    setIsDisabled(true);
    setTimeout(() => setShowFollowUp(true), 300); // Show follow-up for other moods
  };

  const handleDecline = async () => {
    if (!moodSubmitted) {
      await logMood(axiosInstance, 0); // 0 corresponds to 'Declined'
    }
    onClose();
  };

  const handleYes = () => {
    onClose(); // Close the mood prompt
    handleOpenDoodleEditor("drawReasonForMood", { moodId }); // Pass the moodId as context
  };

  const handleNo = () => {
    onClose(); // Handle the case where the user does not want to draw
  };

  return (
    <div className="mood-prompt">
      <div className="mood-header">
        <button className="close-button" onClick={handleDecline}>
          <FontAwesomeIcon icon={faTimes} className="fa-times" />
        </button>
      </div>
      <div className="mood-body">
        <h2>How are you feeling?</h2>
        <div className="mood-options">
          {[1, 2, 3, 4, 5].map((mood) => (
            <button
              key={mood}
              onClick={() => handleMoodSelect(mood)}
              disabled={isDisabled}
              className={`mood-button ${isDisabled ? "disabled" : ""} ${
                mood === selectedMood ? "selected" : ""
              }`}
            >
              <span aria-label={getMoodLabel(mood)}>
                {getMoodIcon(mood)}
              </span>
            </button>
          ))}
        </div>

        {selectedMood !== null && (
          <div
            className={`follow-up-question ${showFollowUp ? "visible" : ""}`}
          >
            <p>
              Would you like to draw what made you feel{" "}
              {getMoodLabel(selectedMood).toLowerCase()}?
            </p>
            <div className="response-buttons">
              <button onClick={handleYes}>Yes</button>
              <button onClick={handleNo}>No</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const getMoodLabel = (mood) => {
  switch (mood) {
    case 1:
      return "Very Sad";
    case 2:
      return "Sad";
    case 3:
      return "Neutral";
    case 4:
      return "Happy";
    case 5:
      return "Very Happy";
    default:
      return "Unknown";
  }
};

const getMoodIcon = (mood) => {
  switch (mood) {
    case 1:
      return <FontAwesomeIcon icon={faFaceSadTear} />;
    case 2:
      return <FontAwesomeIcon icon={faFaceFrown} />;
    case 3:
      return <FontAwesomeIcon icon={faFaceMeh} />;
    case 4:
      return <FontAwesomeIcon icon={faFaceSmile} />;
    case 5:
      return <FontAwesomeIcon icon={faFaceLaugh} />;
    default:
      return <FontAwesomeIcon icon={faFaceMeh} />;
  }
};

export default MoodPrompt;
