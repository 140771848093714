// SendMessageToUser.js

import React, { useContext } from "react";
// import { AxiosContext } from "../App";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMessage } from '@fortawesome/free-solid-svg-icons'

const SendMessageToUser = ({ userId, handleOpenDoodleEditor }) => {
  // const axiosInstance = useContext(AxiosContext);
  // const { handleOpenDoodleEditor } = useDoodleContext();

  return (
    <button className="user-action-button" onClick={() => handleOpenDoodleEditor("sendMessageToUser", userId)}>
      <FontAwesomeIcon icon={faMessage} />
    </button>
  );
};

export default SendMessageToUser;
