// ToolSelector.js
import React from "react";

const ToolSelector = ({
  selectedTool,
  onSelectTool,
  tools,
  toolDurabilities,
}) => {
  return (
    <div className="tool-selector">
      <label htmlFor="tools">Tool: </label>
      <select
        id="tools"
        value={selectedTool}
        onChange={(e) => onSelectTool(e.target.value)}
      >
        {Object.keys(tools).map((key) => {
          // Get current durability from toolDurabilities
          const currentDurability =
            toolDurabilities[key] !== undefined
              ? toolDurabilities[key]
              : Infinity;

          return (
            <option key={key} value={key}>
              {tools[key].label} (
              {currentDurability === Infinity ? "∞" : currentDurability})
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default ToolSelector;
