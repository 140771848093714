// SignOut.js

import React, { useContext } from "react";
import { AxiosContext } from "../App";
import { useUserContext } from "../components/UserContext";
import { useNotifications } from "../components/NotificationsContext";

const SignOut = ({ setIsAuthenticated, icon }) => {
  const axiosInstance = useContext(AxiosContext);
  const { setUserFollows, setUserFollowers, setUserAvatar, setUserId } =
    useUserContext();
  const { setNotifications } = useNotifications();

  const handleSignOut = async () => {
    try {
      await axiosInstance.get(`/logout/`);
      localStorage.removeItem("token");
      setUserFollows(0);
      setUserFollowers(0);
      setUserId(null);
      setUserAvatar(null);
      setIsAuthenticated(false);
      setNotifications({
        unreadMessages: null,
        unreadNotifications: null,
      });
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <a onClick={handleSignOut}>
      {icon && <span className="menu-icon">{icon}</span>}
      Sign out
    </a>
  );
};

export default SignOut;
