import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Post from "../components/Post";
import ReactionsFeed from "../components/ReactionsFeed";
import { useDoodleContext } from "../components/DoodleContext";
import Loader from "../components/Loader";
import usePagination from "../hooks/usePagination";
import { useInView } from "react-intersection-observer";

const Feed = ({ userId, userName, handleOpenDoodleEditor, onFollowChange, isViewingOwnWall }) => {

  // Get username from route
  const { pathUsername } = useParams();

  const {
    doodles,
    setDoodles,
    isReactionsOpen,
    closeReactionsModal,
    postIdForReactionsModal,
    handleCloseDoodleEditor,
  } = useDoodleContext();

  // Use pagination hook
  const {
    items: paginatedDoodles,
    total,
    loading,
    error,
    loadMore,
    hasMore,
  } = usePagination("/feed/", 10);

  // Setup intersection observer only after user interaction
  const [initialized, setInitialized] = useState(false);

  // Update the shared doodles state whenever paginatedDoodles changes
  useEffect(() => {
    setDoodles(paginatedDoodles);
  }, [paginatedDoodles]);

  useEffect(() => {
    const handleScroll = () => {
      setInitialized(true);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Fetch doodles for rendering on the Wall
  const fetchDoodles = () => {
    loadMore().then(() => {
      setInitialized(false); // Reset initialized state after successful page load
    });
  };

  // Fetch initial doodles when component mounts
  useEffect(() => {
    fetchDoodles();
  }, []); // Fetch doodles only once when component mounts

  // Setup intersection observer
  const { ref, inView } = useInView({
    triggerOnce: false,
    rootMargin: "100px",
  });

  // Load more doodles when inView becomes true
  useEffect(() => {
    if (inView && hasMore && !loading && initialized) {
      fetchDoodles(); // Call fetchDoodles instead of loadMore directly
    }
  }, [inView, hasMore, loading, initialized]);

  return (
    <main className="main-content">
      {isReactionsOpen && (
        <div className="closable-overlay">
          <div className="close-button" onClick={closeReactionsModal}></div>
          <div className="overlay-content">
            <ReactionsFeed
              userId={userId}
              userName={userName}
              handleOpenDoodleEditor={handleOpenDoodleEditor}
            />
          </div>
        </div>
      )}
      <div className="feed">
        {doodles.map((doodle) => (
          <Post
            key={doodle.id}
            doodle={doodle}
            typeOfDoodle={"doodles"}
            loggedInUserName={userName}
            pathUsername={pathUsername}
            handleOpenDoodleEditor={handleOpenDoodleEditor}
            onFollowChange={onFollowChange}
            isViewingOwnWall={isViewingOwnWall} // In this case the unreposted post will be instantly removed from feed, which is what we want in this case
          />
        ))}
        {loading && <Loader />}
        {error && <p>Error loading feed</p>}
        {hasMore && <div ref={ref} />}{" "}
        {/* Intersection observer target element */}
      </div>
    </main>
  );
};

export default Feed;
