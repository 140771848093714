// Delete.js

import React, { useContext } from "react";
import { AxiosContext } from "../App";
import { useDoodleContext } from "../components/DoodleContext";

const Delete = ({ doodle, typeOfDoodle }) => {
  const axiosInstance = useContext(AxiosContext);
  const { onDoodleDelete } = useDoodleContext();

  const handleDelete = async () => {
    try {
      await axiosInstance.post(`/doodles/${doodle.id}/delete/`);
      onDoodleDelete(doodle, typeOfDoodle);
    } catch (error) {
      console.error("Error deleting doodle:", error);
    }
  };

  return (
    <div className="menu-item danger" onClick={handleDelete}>
      Delete
    </div>
  );
};

export default Delete;
