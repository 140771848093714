// authUtils.js
// Used for sign in component

import axios from "axios";

const handleSignIn = async (
  username,
  password,
  handleAuthentication,
  setUserId,
  setUserName,
  setUserAvatar,
  setUserFollows,
  setUserFollowers,
  navigate
) => {
  // Function to get the CSRF token from the cookie
  function getCSRFToken() {
    const name = "csrftoken";
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith(name + "="))
      ?.split("=")[1];
    return cookieValue;
  }

  try {
    const response = await axios.post(
      `/api/login/`,
      { username, password },
      {
        headers: {
          "X-CSRFToken": getCSRFToken(),
          "Content-Type": "application/json",
        },
      }
    );
    const token = response.data.token;
    const user_id = response.data.id;
    const user_name = response.data.username;
    const user_avatar = response.data.avatar;
    const user_follows = response.data.follows;
    const user_followers = response.data.followers;
    // save token to local storage
    localStorage.setItem("token", token);
    // update authentication state to true
    handleAuthentication(true);
    // set user id to stats
    setUserId(user_id);
    setUserName(user_name);
    setUserAvatar(user_avatar);
    setUserFollows(user_follows);
    setUserFollowers(user_followers);
    // go to main page
    navigate("/");
  } catch (error) {
    if (error.response) {
      if (error.response.status === 400) {
        throw new Error("Both user name and password are required");
      } else if (error.response.status === 401) {
        throw new Error("Invalid credentials");
      } else if (error.response.status === 500) {
        throw new Error("Something went wrong, please try again later");
      }
    } else {
      throw new Error("Network error, please try again later");
    }
  }
};

export default handleSignIn;
