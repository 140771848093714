import React, { useEffect, useState, useCallback, useRef } from "react";
import Loader from "../components/Loader";
import { formatEntryDate, formatTime } from "../utils/dateUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFaceLaugh,
  faFaceSmile,
  faFaceMeh,
  faFaceFrown,
  faFaceSadTear,
} from "@fortawesome/free-solid-svg-icons";
import { LineChart, Line, Tooltip, XAxis, YAxis, ResponsiveContainer } from "recharts";
import usePagination from "../hooks/usePagination";
import { useInView } from "react-intersection-observer";

const MoodJournal = () => {
  const initialUrl = "/mood/";
  const { items, setItems, loading, error, loadMore, hasMore } = usePagination(
    initialUrl,
    10
  );
  const isDebouncing = useRef(false);

  const { ref, inView } = useInView({
    triggerOnce: false,
    rootMargin: "200px",
  });

  const debouncedLoadMore = useCallback(() => {
    if (!isDebouncing.current && hasMore && !loading) {
      loadMore();
      isDebouncing.current = true;
      setTimeout(() => {
        isDebouncing.current = false;
      }, 100);
    }
  }, [hasMore, loadMore, loading]);

  useEffect(() => {
    if (inView) {
      debouncedLoadMore();
    }
  }, [inView, debouncedLoadMore]);

  const renderMoodIcon = (mood) => {
    switch (mood.toLowerCase()) {
      case "very happy":
        return <FontAwesomeIcon icon={faFaceLaugh} className="mood-icon" />;
      case "happy":
        return <FontAwesomeIcon icon={faFaceSmile} className="mood-icon" />;
      case "neutral":
        return <FontAwesomeIcon icon={faFaceMeh} className="mood-icon" />;
      case "sad":
        return <FontAwesomeIcon icon={faFaceFrown} className="mood-icon" />;
      case "very sad":
        return <FontAwesomeIcon icon={faFaceSadTear} className="mood-icon" />;
      default:
        return null;
    }
  };

  const getMoodLevel = (mood) => {
    switch (mood.toLowerCase()) {
      case "very happy":
        return 5;
      case "happy":
        return 4;
      case "neutral":
        return 3;
      case "sad":
        return 2;
      case "very sad":
        return 1;
      default:
        return 0;
    }
  };

  const groupedEntries = items.reduce((groups, entry) => {
    const date = formatEntryDate(entry.created);
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push({
      ...entry,
      time: formatTime(entry.created),
      moodLevel: getMoodLevel(entry.mood_description),
      createdTimestamp: new Date(entry.created).getTime(),
    });
    return groups;
  }, {});

  return (
    <div className="main-content">
      <div className="feed">
        <div className="mood-journal-page">
          <div className="mood-journal-list">
            {Object.keys(groupedEntries).map((date, index) => {
              const dayEntries = groupedEntries[date];
              const showGraph = dayEntries.length > 1;

              return (
                <div key={index} className="mood-journal-section">
                  <div className="section-date">{date}</div>
                  {showGraph && (
                    <div className="mood-graph">
                      <ResponsiveContainer width="100%" height={100}>
                        <LineChart
                          data={dayEntries}
                          margin={{ top: 5, right: 20, left: 20, bottom: 5 }}
                        >
                          <XAxis
                            dataKey="createdTimestamp"
                            type="number"
                            domain={["dataMin", "dataMax"]}
                            hide
                          />
                          <YAxis
                            type="number"
                            domain={[1, 5]} // Fixed domain from "Very Sad" (1) to "Very Happy" (5)
                            hide
                          />
                          <Line
                            type="linear"
                            dataKey="moodLevel"
                            stroke="var(--interactive-color)"
                            strokeWidth={2}
                            dot={{ r: 2 }}
                            activeDot={{ r: 3 }}
                            isAnimationActive={false}
                          />
                          <Tooltip
                            content={({ active, payload }) =>
                              active && payload && payload.length ? (
                                <div className="custom-tooltip">
                                  {renderMoodIcon(
                                    payload[0].payload.mood_description
                                  )}
                                  <span className="tooltip-text">
                                    &nbsp;{payload[0].payload.mood_description}{" "}
                                    at {payload[0].payload.time}
                                  </span>
                                </div>
                              ) : null
                            }
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </div>
                  )}
                  {dayEntries.map((entry, entryIndex) => (
                    <div key={entryIndex} className="mood-journal-item">
                      <div className="mood-time">{entry.time}</div>
                      {renderMoodIcon(entry.mood_description)}
                      <div className="mood-content">
                        <div className="mood-text">
                          {entry.mood_description.toLowerCase()}
                        </div>
                      </div>
                      {entry.thumbnail && (
                        <img
                          src={entry.thumbnail}
                          alt="doodle-thumbnail"
                          className="doodle-thumbnail"
                        />
                      )}
                    </div>
                  ))}
                </div>
              );
            })}
            {loading && <Loader />}
            {!loading && !hasMore && items.length === 0 && (
              <p>No entries in your mood journal yet.</p>
            )}
            {error && <p>{error}</p>}
            <div ref={ref}></div> {/* Intersection observer target */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoodJournal;
