// Repost.js

import React, { useContext } from "react";
import { AxiosContext } from "../App";
import { useDoodleContext } from "../components/DoodleContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRetweet } from "@fortawesome/free-solid-svg-icons";

const Repost = ({
  doodle,
  reposted,
  isViewingOwnWall,
  typeOfDoodle,
  repostsCounter,
  userOwnsDoodle,
}) => {
  const axiosInstance = useContext(AxiosContext);
  const { onUpdateReposts, onDoodleSubmit } = useDoodleContext();

  const handleRepost = async () => {
    try {
      const action = reposted ? "unrepost" : "repost";
      await axiosInstance
        .post(`/doodles/${doodle.id}/repost/`, { action })
        .then((response) => {
          // If a repost has happened
          if (action == "repost" && isViewingOwnWall) {
            // Add the new repost to the background page (Wall or Feed)
            onDoodleSubmit(response.data, "postOnMyWall");
          }

          // Update button and repost counter on the Reaction in the ReactionsFeed screen
          onUpdateReposts(doodle, !reposted, isViewingOwnWall, typeOfDoodle);
        });
    } catch (error) {
      console.error("Error reposting doodle:", error);
    }
  };

  return (
    <>
      <button
        onClick={!userOwnsDoodle ? () => handleRepost() : null}
        className={`button ${
          userOwnsDoodle ? "inactive" : reposted ? "pressed" : "active"
        }`}
      >
        {" "}
        <FontAwesomeIcon icon={faRetweet} className="icon" />
        <span className="counter">{repostsCounter}</span>
      </button>
    </>
  );
};

export default Repost;
