import React, { useEffect, useState, useCallback } from "react";
import { useDoodleContext } from "../components/DoodleContext";
import Doodle from "../components/Doodle";
import usePagination from "../hooks/usePagination";
import Loader from "../components/Loader";
import { useInView } from "react-intersection-observer";

const ReactionsFeed = ({
  doodles,
  loggedInUserName,
  pathUsername,
  handleOpenDoodleEditor,
  isViewingOwnWall,
}) => {
  const { isReactionsOpen, doodleReactedTo, reactions, setReactions } = useDoodleContext();

  const initialUrl = `/reactions/${doodleReactedTo ? doodleReactedTo.id : ""}`;

  const { items, setItems, total, loading, error, loadMore, hasMore, setUrl } =
    usePagination(initialUrl, 10);

  const [isDebouncing, setIsDebouncing] = useState(false);

  const debouncedLoadMore = useCallback(() => {
    if (!isDebouncing && hasMore && !loading) {
      loadMore();
      setIsDebouncing(true);
      setTimeout(() => {
        setIsDebouncing(false);
      }, 100);
    }
  }, [isDebouncing, hasMore, loading, loadMore]);

  useEffect(() => {
    if (isReactionsOpen && doodleReactedTo) {
      setUrl(`/reactions/${doodleReactedTo.id}`);
      setItems([]);
    }
  }, [isReactionsOpen, doodleReactedTo, setUrl, setItems]);

  const { ref, inView } = useInView({
    triggerOnce: false,
    rootMargin: "200px",
  });

  useEffect(() => {
    if (inView && hasMore && !loading) {
      debouncedLoadMore();
    }
  }, [inView, hasMore, loading, debouncedLoadMore]);

  useEffect(() => {
    setReactions(items);
  }, [items, setReactions]);

  return (
    <>
      <div className="feed">
        {/* Original post */}
        <div className="post">
          {doodleReactedTo && (
            <Doodle
              doodle={doodleReactedTo}
              typeOfDoodle={"reactions"}
              handleOpenDoodleEditor={handleOpenDoodleEditor}
            />
          )}
        </div>
      </div>

      {/* Reactions feed */}
      <div className="feed">
        <h2>Reactions:</h2>
        {reactions.map((doodle) => (
          <div className="post" key={doodle.id}>
            <Doodle
              key={doodle.id}
              doodle={doodle}
              doodles={doodles}
              typeOfDoodle={"reactions"}
              pathUsername={pathUsername}
              loggedInUserName={loggedInUserName}
              handleOpenDoodleEditor={handleOpenDoodleEditor}
              isViewingOwnWall={isViewingOwnWall}
              reactionsButtonDisabled={true}
            />
          </div>
        ))}
        {loading && <Loader />}
        {!loading && !hasMore && reactions.length === 0 && <p>Nothing here yet.</p>}
        {error && <p>{error}</p>}
        <div ref={ref}></div> {/* Intersection observer target */}
      </div>
    </>
  );
};

export default ReactionsFeed;
