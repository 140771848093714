import React, { createContext, useState, useEffect, useContext } from "react";
import { AxiosContext } from "../App";

const NotificationsContext = createContext();

export const NotificationsProvider = ({
  children,
  isAuthenticated,
  setUserScore,
  setUserLevel,
  setLevelCompletionPercent,
}) => {
  const [notifications, setNotifications] = useState({
    unreadMessages: null,
    unreadNotifications: null,
  });

  const axiosInstance = useContext(AxiosContext); // Use the existing axiosInstance from context

  useEffect(() => {
    if (!isAuthenticated) return;

    const fetchNotifications = async () => {
      try {
        const response = await axiosInstance.get("/notifications/counters/");
        const { unread_messages, unread_notifications, open_challenges_count } = response.data;
        setNotifications({
          unreadMessages: unread_messages,
          unreadNotifications: unread_notifications,
          openedChallanges: open_challenges_count,
        });
      } catch (error) {
        console.error("Failed to fetch notifications", error);
      }
    };

    fetchNotifications();
    const interval = setInterval(fetchNotifications, 60000); // Fetch every minute

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [isAuthenticated]);

  const incrementUnreadCounter = (amount, counterType) => {
    setNotifications((prevNotifications) => ({
      ...prevNotifications,
      [counterType]: prevNotifications[counterType] + amount,
    }));
  };

  return (
    <NotificationsContext.Provider
      value={{ notifications, setNotifications, incrementUnreadCounter }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotifications = () => {
  return useContext(NotificationsContext);
};
