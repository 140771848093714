// Mainpage.js

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Wall from "../components/Wall";
import Loader from "../components/Loader";
import ReactionsFeed from "../components/ReactionsFeed";
import { useDoodleContext } from "../components/DoodleContext";
import usePagination from "../hooks/usePagination";
import { useInView } from "react-intersection-observer";
import { useLocation } from "react-router-dom";

const Mainpage = ({ userId, userName, handleOpenDoodleEditor }) => {
  // Get username from route
  const { pathUsername } = useParams();
  const location = useLocation();

  // Determine if the user is viewing his own Wall
  const isViewingOwnWall = pathUsername === userName;

  const currentApiUrl = pathUsername ? `/doodles/${pathUsername}` : "/doodles/";

  const {
    doodles,
    setDoodles,
    onUpdateLikes,
    isReactionsOpen,
    closeReactionsModal,
  } = useDoodleContext();

  // Use pagination hook
  const {
    items: paginatedDoodles,
    setItems,
    total,
    loading,
    error,
    loadMore,
    hasMore,
    url,
    setUrl,
    page,
    setPage,
  } = usePagination(
    pathUsername ? `/doodles/${pathUsername}` : "/doodles/",
    10
  );

  // Update the shared doodles state whenever paginatedDoodles changes
  useEffect(() => {
    setDoodles(paginatedDoodles);
  }, [paginatedDoodles]);

  // Setup intersection observer only after user interaction
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setInitialized(true);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Fetch doodles for rendering on the Wall
  const fetchDoodles = () => {
    loadMore().then(() => {
      setInitialized(false); // Reset initialized state after successful page load
    });
  };

  // State for managing pagination status reset
  const [paginationResetNeeded, setPaginationResetNeeded] = useState(false);

  useEffect(() => {
    // If url is current, page is 1, and paginatedDoodles is empty, then it is the default state -  fetch doodles
    if (url === currentApiUrl && page === 1 && paginatedDoodles.length === 0) {
      fetchDoodles();
    } else {
    // If the above condition is not met, then the page was switched from one user to enother, and reset of pagination states will be required
      setPaginationResetNeeded(true);
    }
  }, [pathUsername]); // Reset and fetch doodles whenever the username changes

  useEffect(() => {
    // Reset states in the usePagination hook if paginationResetNeeded=true
    if (paginationResetNeeded) {
      setItems([]);
      setUrl(currentApiUrl);
      setPage(1);
    }
  }, [paginationResetNeeded]);

  useEffect(() => {
    // Check if pagination states have finished resetting
    if (url === currentApiUrl && page === 1 && paginationResetNeeded) {
      // If all pagination states have finished resetting, then fetch doodles
      fetchDoodles();
      // And switch off the paginationResetNeeded state
      setPaginationResetNeeded(false);
    }
  }, [url, page, paginatedDoodles]);

  // Setup intersection observer
  const { ref, inView } = useInView({
    triggerOnce: false,
    rootMargin: "100px",
  });

  // Load more doodles when inView becomes true
  useEffect(() => {
    if (inView && hasMore && !loading && initialized) {
      fetchDoodles(); // Call fetchDoodles instead of loadMore directly
    }
  }, [inView, hasMore, loading, initialized]);

  return (
    <main className="main-content">
      {isReactionsOpen && (
        <div className="closable-overlay">
          <div className="close-button" onClick={closeReactionsModal}></div>
          <div className="overlay-content">
            <ReactionsFeed
              userId={userId}
              userName={userName}
              handleOpenDoodleEditor={handleOpenDoodleEditor}
              isViewingOwnWall={isViewingOwnWall}
            />
          </div>
        </div>
      )}
      <Wall
        doodles={doodles}
        onUpdateLikes={onUpdateLikes}
        loggedInUserId={userId}
        loggedInUserName={userName}
        pathUsername={pathUsername}
        handleOpenDoodleEditor={handleOpenDoodleEditor}
      />
      {loading && <Loader />}
      {error && <p>{error}</p>}
      {hasMore && <div ref={ref} />}{" "}
      {/* Intersection observer target element */}
    </main>
  );
};

export default Mainpage;
