import React, { useState, useRef, useEffect, useCallback } from "react";
import Message from "./Message";
import UserAvatar from "./UserAvatar";
import SendMessageToChat from "./SendMessageToChat";
import { useDoodleContext } from "../components/DoodleContext";
import { Link } from "react-router-dom";
import usePagination from "../hooks/usePagination";
import { useInView } from "react-intersection-observer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const ChatOverlay = ({
  chatId,
  userName,
  onClose,
  handleOpenDoodleEditor,
  companionUsername,
  companionAvatar,
}) => {
  const { items, loadMore, hasMore, loading } = usePagination(
    `/chats/${chatId}/`,
    20,
    true
  );

  const [isDebouncing, setIsDebouncing] = useState(false);
  const chatContentRef = useRef(null);
  const { chatMessages, setChatMessages } = useDoodleContext();
  const messagesEndRef = useRef(null);
  const initialLoad = useRef(true);
  const previousScrollHeightRef = useRef(0);

  const debouncedLoadMore = useCallback(() => {
    if (!isDebouncing && hasMore && !loading) {
      loadMore();
      setIsDebouncing(true);
      setTimeout(() => {
        setIsDebouncing(false);
      }, 2000);
    }
  }, [isDebouncing, hasMore, loading, loadMore]);

  const { ref, inView } = useInView({
    triggerOnce: false,
    rootMargin: "200px",
  });

  const scrollToBottom = (behavior = "smooth") => {
    messagesEndRef.current?.scrollIntoView({ behavior });
  };

  const handleNewMessage = () => {
    scrollToBottom();
  };

  const handleLoadMore = () => {
    previousScrollHeightRef.current = chatContentRef.current.scrollHeight;
    debouncedLoadMore();
  };

  useEffect(() => {
    setChatMessages(items);
    if (initialLoad.current) {
      scrollToBottom("auto");
      initialLoad.current = false;
    }
  }, [items, setChatMessages]);

  useEffect(() => {
    if (chatMessages.length > 0) {
      const currentScrollHeight = chatContentRef.current.scrollHeight;
      const scrollDifference =
        currentScrollHeight - previousScrollHeightRef.current;
      if (scrollDifference > 0) {
        chatContentRef.current.scrollTop += scrollDifference;
      }
    }
  }, [chatMessages]);

  useEffect(() => {
    if (inView && hasMore && !loading) {
      handleLoadMore();
    }
  }, [inView, hasMore, loading, debouncedLoadMore]);

  return (
    <div className="chat-overlay">
      <div className="chat-header">
        <Link to={`/${companionUsername}`}>
          <UserAvatar
            avatarUrl={companionAvatar}
            username={companionUsername}
          />
        </Link>
        <div className="user-name">
          <Link to={`/${companionUsername}`}>{companionUsername}</Link>
        </div>
        <button className="close-button" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} className="fa-times" />
        </button>
      </div>
      <div className="chat-content" ref={chatContentRef}>
        <div ref={ref}></div> {/* Intersection observer target */}
        {chatMessages.map((message, index) => (
          <Message
            key={index}
            message={message}
            userName={userName}
            companionUsername={companionUsername}
            companionAvatar={companionAvatar}
          />
        ))}
        <div ref={messagesEndRef} />
      </div>
      <button className="new-message-button">
        <SendMessageToChat
          chatId={chatId}
          handleOpenDoodleEditor={handleOpenDoodleEditor}
        />
      </button>
    </div>
  );
};

export default ChatOverlay;
