// axiosInstance.js

import axios from "axios";
import { BASE_URL } from "./config";
import { Navigate } from "react-router-dom";

const createAxiosInstance = (
  setIsAuthenticated,
  setUserScore,
  setUserLevel,
  setLevelCompletionPercent,
  setSpawnMessage,
  setShowMoodPrompt
) => {
  const instance = axios.create({
    baseURL: BASE_URL,
  });

  // Function to get the CSRF token from the cookie
  function getCSRFToken() {
    const name = "csrftoken";
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith(name + "="))
      ?.split("=")[1];
    return cookieValue;
  }

  instance.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");
    const csrfToken = getCSRFToken(); // Retrieve the CSRF token

    if (token) {
      config.headers.Authorization = `Token ${token}`;
    }
    if (csrfToken) {
      config.headers["X-CSRFToken"] = csrfToken;
    }
    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      if (response.data.new_score !== undefined) {
        setUserScore(response.data.new_score);
      }
      if (response.data.new_level !== undefined) {
        setUserLevel(response.data.new_level);
      }
      if (response.data.new_level_completion_percent !== undefined) {
        setLevelCompletionPercent(response.data.new_level_completion_percent);
      }
      if (response.data.spawn_message !== undefined) {
        setSpawnMessage(response.data.spawn_message);
      }
      if (response.data.show_mood_prompt !== undefined) {
        setShowMoodPrompt(response.data.show_mood_prompt);
      }
      return response;
    },
    (error) => {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500
      ) {
        // Handle 4xx errors
        if (error.response.status === 401) {
          // Unauthorized or Forbidden, update authentication state and redirect to sign-in page
          setIsAuthenticated(false);
          localStorage.removeItem("token");
          // Redirect to sign-in page
          return Promise.reject(<Navigate to="/signin" />);
        }
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

export default createAxiosInstance;
