import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import HelpTooltip from "../HelpTooltip";

const UserProgressDetails = ({
  userScore,
  userLevel,
  levelCompletionPercent,
}) => {
  return (
    <div className="user-progress-details">
      <div className="score-section">
        <div className="label-with-icon">
          <div className="section-label">Score</div>
          <HelpTooltip content="Your score increases as you post content that other users like, invite new members, and complete challenges. A higher score boosts your level and improves your position in the rating.">
            <span className="info-icon">
              <FontAwesomeIcon icon={faCircleQuestion} />
            </span>
          </HelpTooltip>
        </div>
        <div className="user-score">{userScore}</div>
      </div>

      <div className="level-section">
        <div className="label-with-icon">
          <div className="section-label">Level {userLevel}</div>
          <HelpTooltip content="A higher level unlocks more and rarer drawing tools and colors, giving you more creative possibilities!">
            <span className="info-icon">
              <FontAwesomeIcon icon={faCircleQuestion} />
            </span>
          </HelpTooltip>
        </div>

        {userScore ? (
          <div className="progress-bar-container">
            <div
              className="progress-bar"
              style={{ width: `${levelCompletionPercent}%` }}
            ></div>
            <div className="progress-bar-text">
              {`${levelCompletionPercent}% complete`}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default UserProgressDetails;
