import React, { useState, useEffect, useContext } from "react";
import { AxiosContext } from "../App";
import { useLocation, useParams } from "react-router-dom";
import AddProfileEntry from "../components/AddProfileEntry";
import ProfileEntriesFeed from "../components/ProfileEntriesFeed";
import { useDoodleContext } from "../components/DoodleContext";
import Loader from "../components/Loader";

const Profile = ({ userName, handleOpenDoodleEditor }) => {
  const axiosInstance = useContext(AxiosContext);
  const [loading, setLoading] = useState(true);
  const [imageLoadingStates, setImageLoadingStates] = useState({});

  const {
    usernameForProfilePage,
    setUsernameForProfilePage,
    profileEntriesPage,
    setProfileEntriesPage,
    isProfileEntriesFeedOpen,
    setProfileEntryTypeObject,
    setIsProfileEntriesFeedOpen,
  } = useDoodleContext();

  const { pathUsername } = useParams();
  const location = useLocation();
  const isViewingOwnProfile =
    location.pathname === "/profile" || pathUsername === userName;

  useEffect(() => {
    const fetchProfileEntries = async () => {
      const usernameToFetch = isViewingOwnProfile ? userName : pathUsername;
      if (usernameToFetch) {
        try {
          const response = await axiosInstance.get(
            `/profile/${usernameToFetch}`
          );
          setProfileEntriesPage(response.data);
          setUsernameForProfilePage(usernameToFetch);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching profile entries:", error);
        }
      }
    };

    fetchProfileEntries();
  }, [isViewingOwnProfile, userName, pathUsername]);

  const handleImageLoad = (id) => {
    setImageLoadingStates((prevStates) => ({
      ...prevStates,
      [id]: true,
    }));
  };

  const handleOpenProfileEntriesFeed = (entryTypeObject) => {
    setProfileEntryTypeObject(entryTypeObject);
    setIsProfileEntriesFeedOpen(true);
  };

  const handleCloseProfileEntriesFeed = () => {
    setIsProfileEntriesFeedOpen(false);
  };

  if (loading) {
    return <Loader />; // Render a loader component while data is being fetched
  }

  return (
    <div className="main-content">
      {isProfileEntriesFeedOpen && (
        <div className="closable-overlay">
          <div
            className="close-button"
            onClick={handleCloseProfileEntriesFeed}
          ></div>
          <div className="overlay-content">
            <ProfileEntriesFeed
              isViewingOwnProfile={isViewingOwnProfile}
              handleOpenDoodleEditor={handleOpenDoodleEditor}
            />
          </div>
        </div>
      )}
      <div className="profile-page">
        <h1>{usernameForProfilePage}'s bio</h1>
        {profileEntriesPage.map((entryType) => (
          <div className="entry-type" key={entryType.id}>
            <div className="entry-type__header">
              <h2>{entryType.label}</h2>
              {isViewingOwnProfile && (
                <AddProfileEntry
                  entryType={entryType}
                  handleOpenDoodleEditor={handleOpenDoodleEditor}
                  isViewingOwnProfile={isViewingOwnProfile}
                />
              )}
            </div>
            <div
              className="entry-type__content"
              onClick={() => handleOpenProfileEntriesFeed(entryType)}
            >
              {entryType.doodles &&
                entryType.doodles.length > 0 &&
                entryType.doodles.map((doodle) => (
                  <div className="entry" key={doodle.id}>
                    <div
                      className={`thumbnail-container ${
                        imageLoadingStates[doodle.id] ? "" : "loading"
                      }`}
                    >
                      <img
                        src={doodle.image}
                        alt={`Doodle ${doodle.id}`}
                        onLoad={() => handleImageLoad(doodle.id)}
                        className={
                          imageLoadingStates[doodle.id] ? "loaded" : ""
                        }
                      />
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Profile;
