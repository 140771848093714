import React from "react";

const ColorPicker = ({
  selectedColor,
  selectedColorLabel,
  onSelectColor,
  colors,
  hasPalette,
  colorQuantities,
}) => {
  const currentColorKey = Object.keys(colors).find(
    (key) => colors[key].color === selectedColor
  );

  return (
    <div className="color-picker">
      <label htmlFor="colors">Color: </label>
      {hasPalette ? (
        <select
          id="colors"
          value={selectedColor}
          onChange={(e) => {
            const colorKey = Object.keys(colors).find(
              (key) => colors[key].color === e.target.value
            );
            onSelectColor(e.target.value, colors[colorKey].label);
          }}
        >
          {Object.keys(colors).map((key) => (
            <option key={key} value={colors[key].color}>
              {colors[key].label} (
              {colorQuantities[key] === Infinity ? "∞" : colorQuantities[key]})
            </option>
          ))}
        </select>
      ) : (
        // Show a label instead of select if no palette is available
        <span>
          {selectedColorLabel} (
          {colorQuantities[currentColorKey] === Infinity
            ? "∞"
            : colorQuantities[currentColorKey]}
          )
        </span>
      )}
    </div>
  );
};

export default ColorPicker;