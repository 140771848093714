// SendMessageToChat.js

import React from "react";

const SendMessageToChat = ({ chatId, handleOpenDoodleEditor }) => {
  return (
    <div className="new-message-button" onClick={() => handleOpenDoodleEditor("sendMessageToChat", chatId)}>
      +
    </div>
  );
};

export default SendMessageToChat;
