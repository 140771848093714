import React, { useContext, useState, useEffect } from "react";
import { AxiosContext } from "../App";
import Loader from "./Loader";

const Follow = ({
  followed: initialFollowed,
  userId,
  onFollowChange,
  isInList,
  className,
  customElement,
}) => {
  const axiosInstance = useContext(AxiosContext);
  const [followed, setFollowed] = useState(initialFollowed);
  const [loading, setLoading] = useState(initialFollowed === null);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (initialFollowed === null) {
      fetchFollowStatus();
    }
  }, [initialFollowed]);

  const fetchFollowStatus = async () => {
    try {
      const response = await axiosInstance.get(
        `/check-if-user-is-followed/${userId}`
      );
      setFollowed(response.data.followed);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleFollow = async () => {
    try {
      const action = followed ? "unfollow" : "follow";
      await axiosInstance.post(`/follow/`, { userId, action });
      setFollowed(!followed);
      onFollowChange(!followed, userId);
    } catch (error) {
      console.error("Error following:", error);
    }
  };

  return (
    <>
      {!error ? (
        <div
          className={`${className} ${followed ? "active" : ""}`} // Apply active class based on follow state
          onClick={handleFollow}
          disabled={loading}
        >
          {loading ? (
            <Loader />
          ) : customElement ? (
            customElement
          ) : followed ? (
            "Following"
          ) : (
            "Follow"
          )}
        </div>
      ) : (
        <div>Error, try later</div>
      )}
    </>
  );
};

export default Follow;