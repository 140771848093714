import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const ReactionsPreview = ({ doodle, openReactionsModal }) => {
  const [loadedStates, setLoadedStates] = useState(
    doodle.reactions.map(() => false)
  );

  useEffect(() => {
    setLoadedStates(doodle.reactions.map(() => false));
  }, [doodle.reactions]);

  const handleImageLoad = (index) => {
    setLoadedStates((prevStates) =>
      prevStates.map((state, i) => (i === index ? true : state))
    );
  };

  return (
    <div
      className="reaction-previews"
      onClick={() => openReactionsModal(doodle)}
    >
      {doodle.reactions && doodle.reactions.length > 0 ? (
        doodle.reactions.map((reaction, index) => (
          <div key={index} className="entry">
            <Link
              className="username"
              to={`/${reaction.belongs_to_user.username}`}
            >
              {reaction.belongs_to_user.username}
            </Link>
            <div className={`thumbnail-container ${loadedStates[index] ? "" : "loading"}`}>
              <img
                className={`thumbnail ${loadedStates[index] ? "loaded" : ""}`}
                src={reaction.thumbnail}
                alt={`Reaction ${index}`}
                onLoad={() => handleImageLoad(index)}
              />
            </div>
          </div>
        ))
      ) : (
        <p>No reactions yet.</p>
      )}
    </div>
  );
};

export default ReactionsPreview;
