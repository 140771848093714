import React, { useRef } from "react";
import { Link } from "react-router-dom";
import UserMeta from "./UserMeta";
import SignOut from "./SignOut";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faHouse,
  faBell,
  faMessage,
  faRightFromBracket,
  faClone,
  faHeartbeat,
  faGear,
  faBurst,
} from "@fortawesome/free-solid-svg-icons";
import { useNotifications } from "./NotificationsContext";

const SideMenu = ({
  isSideMenuOpen,
  toggleSideMenu,
  menuRef,
  userName,
  userAvatar,
  userFollows,
  userFollowers,
  openOverlay,
  setIsAuthenticated,
  userScore,
  userLevel,
  levelCompletionPercent,
  toggleProgressOverlay,
}) => {
  const { notifications } = useNotifications();

  return (
    <aside
      ref={menuRef}
      className={`menu-screen ${isSideMenuOpen ? "show-menu" : ""}`}
    >
      <UserMeta
        userName={userName}
        avatar={userAvatar?.thumbnail}
        followsCount={userFollows}
        followersCount={userFollowers}
        openOverlay={openOverlay}
        userLevel={userLevel}
        userScore={userScore}
        levelCompletionPercent={levelCompletionPercent}
        toggleProgressOverlay={toggleProgressOverlay}
        toggleSideMenu={toggleSideMenu}
      />
      <div className="menu-links">
        <Link to={`/${userName}`} aria-label="Wall" onClick={toggleSideMenu}>
          <FontAwesomeIcon icon={faClone} />
          Wall
        </Link>
        <Link to="/profile" aria-label="Bio" onClick={toggleSideMenu}>
          <FontAwesomeIcon icon={faAddressCard} />
          Bio
        </Link>
        <Link to="/" aria-label="Feed" onClick={toggleSideMenu}>
          <FontAwesomeIcon icon={faHouse} />
          Feed
        </Link>
        <Link
          to="/mood-journal"
          aria-label="Mood journal"
          onClick={toggleSideMenu}
        >
          <FontAwesomeIcon icon={faHeartbeat} />
          Mood journal
        </Link>
        <Link to="/challenges" aria-label="Challenges" onClick={toggleSideMenu}>
          <FontAwesomeIcon icon={faBurst} />
          Challenges
          {notifications.openedChallanges > 0 && (
            <div className="notifications-counter">
              {notifications.openedChallanges}
            </div>
          )}
        </Link>
        <Link
          to="/notifications"
          aria-label="Notifications"
          onClick={toggleSideMenu}
        >
          <FontAwesomeIcon icon={faBell} />
          Notifications
          {notifications.unreadNotifications > 0 && (
            <div className="notifications-counter">
              {notifications.unreadNotifications}
            </div>
          )}
        </Link>
        <Link to="/chats" aria-label="Chats" onClick={toggleSideMenu}>
          <FontAwesomeIcon icon={faMessage} />
          Chats
          {notifications.unreadMessages > 0 && (
            <div className="notifications-counter">
              {notifications.unreadMessages}
            </div>
          )}
        </Link>
        <Link to="/settings" aria-label="Settings" onClick={toggleSideMenu}>
          <FontAwesomeIcon icon={faGear} />
          Settings
        </Link>
        <SignOut
          setIsAuthenticated={setIsAuthenticated}
          icon={<FontAwesomeIcon icon={faRightFromBracket} />}
        />
      </div>
    </aside>
  );
};

export default SideMenu;
