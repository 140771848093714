// Post.js

import React from "react";
import Doodle from "./Doodle";
import ReactionsPreview from "./ReactionsPreview";
import { useDoodleContext } from "../components/DoodleContext";

const Post = ({
  doodle,
  isViewingOwnWall,
  handleOpenDoodleEditor,
  onFollowChange,
}) => {
  const { openReactionsModal } = useDoodleContext();

  return (
    <div className="post" key={doodle.id}>
      <Doodle
        doodle={doodle}
        isViewingOwnWall={isViewingOwnWall}
        handleOpenDoodleEditor={handleOpenDoodleEditor}
        typeOfDoodle={"doodles"}
        onFollowChange={onFollowChange}
      />
      {doodle.reactions && doodle.reactions.length > 0 && (
        <>
          <hr className="separator" />
          <ReactionsPreview
            doodle={doodle}
            openReactionsModal={openReactionsModal}
          />
        </>
      )}
    </div>
  );
};

export default Post;
