import React, { useState, useContext, useEffect, useRef } from "react";
import { AxiosContext } from "../../App";
import { useDoodleContext } from "../DoodleContext";
import ToolSelector from "./ToolSelector";
import ColorPicker from "./ColorPicker";
import Loader from "../Loader";
import DrawingArea from "./DrawingArea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const DoodleEditor = ({ onClose, editorIntention, editorContext, doodleToEditId = null }) => {
  const axiosInstance = useContext(AxiosContext);
  const { onDoodleSubmit } = useDoodleContext();
  const [lines, setLines] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false);
  const [selectedTool, setSelectedTool] = useState("charcoal");
  const [selectedColor, setSelectedColor] = useState("#000000");
  const [selectedColorLabel, setSelectedColorLabel] = useState("Black");
  const [colorQuantities, setColorQuantities] = useState({});
  const [toolDurabilities, setToolDurabilities] = useState({});
  const [colorHistory, setColorHistory] = useState([]);
  const [toolHistory, setToolHistory] = useState([]);
  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const [brushTextureSrc, setBrushTextureSrc] = useState(null);
  const [colorSpendFactor, setColorSpendFactor] = useState(1);
  const [durabilitySpendFactor, setDurabilitySpendFactor] = useState(1);
  const [isPosting, setIsPosting] = useState(false);
  const changedPixels = useRef({});
  const allChangedPixels = useRef({});
  const exactQuantities = useRef({});
  const initialQuantities = useRef({});
  const exactDurabilities = useRef({});
  const initialDurabilities = useRef({});
  const [shareOnWall, setShareOnWall] = useState(false);
  const [existingDoodleImage, setExistingDoodleImage] = useState(null); // State for the existing doodle image

  useEffect(() => {
    // Prepare the payload, including the doodleToEditId if provided
    const payload = doodleToEditId ? { get_doodle_id: doodleToEditId } : {};
    console.log(doodleToEditId);
    console.log(payload);

    axiosInstance
      .post("/editor/", payload)
      .then((response) => {
        setConfig(response.data);
        const initialQuantitiesData = {};
        const initialDurabilitiesData = {};

        Object.keys(response.data.colors).forEach((key) => {
          initialQuantitiesData[key] =
            response.data.colors[key].quantity || Infinity;
          changedPixels.current[key] = new Set();
          allChangedPixels.current[key] = new Set();
          exactQuantities.current[key] =
            response.data.colors[key].quantity || Infinity;
        });

        Object.keys(response.data.tools).forEach((key) => {
          initialDurabilitiesData[key] =
            response.data.tools[key].durability || Infinity;
          exactDurabilities.current[key] =
            response.data.tools[key].durability || Infinity;
        });

        // Initialize for tools with default colors
        Object.values(response.data.tools).forEach((tool) => {
          if (!tool.has_palette) {
            const colorCode = tool.default_color.color;
            if (!initialQuantitiesData[colorCode]) {
              initialQuantitiesData[colorCode] = Infinity;
              changedPixels.current[colorCode] = new Set();
              allChangedPixels.current[colorCode] = new Set();
              exactQuantities.current[colorCode] = Infinity;
            }
          }
        });

        setColorQuantities(initialQuantitiesData);
        setToolDurabilities(initialDurabilitiesData);
        initialQuantities.current = { ...initialQuantitiesData };
        initialDurabilities.current = { ...initialDurabilitiesData };
        setColorSpendFactor(response.data.color_spend_factor || 1);
        setDurabilitySpendFactor(response.data.durability_spend_factor || 1);

        // If editing an existing doodle, set the existing image
        if (response.data.existing_doodle && response.data.existing_doodle.doodle_image) {
          setExistingDoodleImage(response.data.existing_doodle.doodle_image);
        }

        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching editor config:", error);
        setLoading(false);
      });
  }, [axiosInstance, doodleToEditId]);

  useEffect(() => {
    if (config) {
      const tool = config.tools[selectedTool];
      setBrushTextureSrc(tool.texture);
      if (!tool.has_palette) {
        setSelectedColor(tool.default_color.color);
        setSelectedColorLabel(tool.default_color.name);
      } else {
        const defaultColorKey = Object.keys(config.colors)[0];
        setSelectedColor(config.colors[defaultColorKey].color);
        setSelectedColorLabel(config.colors[defaultColorKey].label);
      }
    }
  }, [config, selectedTool]);

  const handleUndo = () => {
    if (lines.length === 0) return;

    const lastLine = lines[lines.length - 1];
    const colorKey = lastLine.colorKey;
    const toolKey = lastLine.tool;

    if (exactQuantities.current[colorKey] !== Infinity) {
      const lastHistory = colorHistory[colorHistory.length - 1];
      exactQuantities.current[colorKey] = lastHistory.initialQuantity;
      setColorQuantities((prevQuantities) => ({
        ...prevQuantities,
        [colorKey]: Math.round(lastHistory.initialQuantity),
      }));
      setColorHistory((prevHistory) => prevHistory.slice(0, -1));
      allChangedPixels.current[colorKey].forEach((pixel) => {
        changedPixels.current[colorKey].delete(pixel);
      });
    }

    if (exactDurabilities.current[toolKey] !== Infinity) {
      const lastToolHistory = toolHistory[toolHistory.length - 1];
      exactDurabilities.current[toolKey] = lastToolHistory.initialDurability;
      setToolDurabilities((prevDurabilities) => ({
        ...prevDurabilities,
        [toolKey]: Math.round(lastToolHistory.initialDurability),
      }));
      setToolHistory((prevHistory) => prevHistory.slice(0, -1));
    }

    setLines((prevLines) => prevLines.slice(0, -1));
  };

  const handleClear = () => {
    setLines([]);

    // Reset color quantities
    const resetQuantities = {};
    Object.keys(colorQuantities).forEach((key) => {
      if (config.colors[key]) {
        resetQuantities[key] = config.colors[key].quantity || Infinity;
        exactQuantities.current[key] = config.colors[key].quantity || Infinity;
      }
    });

    // Reset tool durabilities
    const resetDurabilities = {};
    Object.keys(toolDurabilities).forEach((key) => {
      if (config.tools[key]) {
        resetDurabilities[key] = config.tools[key].durability || Infinity;
        exactDurabilities.current[key] =
          config.tools[key].durability || Infinity;
      }
    });

    setColorQuantities(resetQuantities);
    setToolDurabilities(resetDurabilities);
    initialQuantities.current = { ...resetQuantities };
    initialDurabilities.current = { ...resetDurabilities };
    setColorHistory([]);
    setToolHistory([]);

    // Clear changed pixels
    Object.keys(allChangedPixels.current).forEach((key) => {
      if (changedPixels.current[key]) {
        allChangedPixels.current[key].clear();
        changedPixels.current[key].clear();
      }
    });
  };

  const handlePost = () => {
    setIsPosting(true); // Lock the button and show the loader
    const stage = document.querySelector("canvas");
    stage.toBlob((blob) => {
      if (blob) {
        const formData = new FormData();
        formData.append("image", blob, "doodle.webp");
        formData.append("intention", editorIntention);

        // Form context based on the intention
        const context =
          editorIntention === "drawReasonForMood"
            ? { ...editorContext, shareOnWall }
            : editorContext;

        formData.append("context", JSON.stringify(context));

        const spentQuantities = {};
        Object.keys(exactQuantities.current).forEach((key) => {
          const spent =
            initialQuantities.current[key] - exactQuantities.current[key];
          if (spent > 0) {
            spentQuantities[config.colors[key].id] = Math.round(spent);
          }
        });

        formData.append("colors", JSON.stringify(spentQuantities));

        const spentDurabilities = {};
        Object.keys(exactDurabilities.current).forEach((key) => {
          const spent =
            initialDurabilities.current[key] - exactDurabilities.current[key];
          if (spent > 0) {
            spentDurabilities[key] = Math.round(spent);
          }
        });

        formData.append("tools", JSON.stringify(spentDurabilities));

        axiosInstance
          .post("/doodles/post/", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            onClose();
            var new_doodle = response.data;
            onDoodleSubmit(new_doodle, editorIntention, context);
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setIsPosting(false); // Unlock the button and hide the loader after submission
          });
      } else {
        console.error("Failed to create blob from canvas data");
        setIsPosting(false); // Unlock the button even if blob creation fails
      }
    }, "image/webp");
  };

  const handleCheckboxChange = (event) => {
    setShareOnWall(event.target.checked);
  };

  return (
    <div className="editor-overlay">
      {loading ? (
        <Loader /> // Display the loader
      ) : (
        <div className="editor">
          <button className="close-button" onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} className="fa-times" />
          </button>
          <div className="canvas-wrapper">
            <DrawingArea
              lines={lines}
              setLines={setLines}
              selectedTool={selectedTool}
              selectedColor={selectedColor}
              config={config}
              colorQuantities={colorQuantities}
              setColorQuantities={setColorQuantities}
              colorSpendFactor={colorSpendFactor}
              durabilitySpendFactor={durabilitySpendFactor}
              exactQuantities={exactQuantities}
              exactDurabilities={exactDurabilities}
              changedPixels={changedPixels}
              allChangedPixels={allChangedPixels}
              colorHistory={colorHistory}
              setColorHistory={setColorHistory}
              toolDurabilities={toolDurabilities}
              setToolDurabilities={setToolDurabilities}
              toolHistory={toolHistory}
              setToolHistory={setToolHistory}
              isDrawing={isDrawing}
              setIsDrawing={setIsDrawing}
              brushTextureSrc={brushTextureSrc}
              existingDoodleImage={existingDoodleImage} // Include the existing doodle image if available
            />
          </div>
          <div className="editor-toolbar">
            <div className="editing-buttons">
              <button onClick={handleUndo} disabled={lines.length === 0}>
                Undo
              </button>
              <button onClick={handleClear}>Clear</button>
            </div>
            <ToolSelector
              selectedTool={selectedTool}
              onSelectTool={setSelectedTool}
              tools={config.tools}
              toolDurabilities={toolDurabilities}
            />
            <ColorPicker
              selectedColor={selectedColor}
              selectedColorLabel={selectedColorLabel}
              onSelectColor={(color, label) => {
                setSelectedColor(color);
                setSelectedColorLabel(label);
              }}
              colors={config.colors}
              hasPalette={config.tools[selectedTool].has_palette}
              colorQuantities={colorQuantities}
            />
            <div className="post-button">
              <button onClick={handlePost} disabled={isPosting}>
                {isPosting ? <Loader size="small" /> : "Post"}
              </button>
            </div>
            {editorIntention === "drawReasonForMood" && (
              <div className="share-on-wall">
                <label>
                  <input
                    type="checkbox"
                    checked={shareOnWall}
                    onChange={handleCheckboxChange}
                  />
                  Share on Wall
                </label>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DoodleEditor;
