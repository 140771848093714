// Wall.js

import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Post from "../components/Post";
import Follow from "../components/Follow";
import UserMeta from "../components/UserMeta";
import SendMessageToUser from "../components/SendMessageToUser";
import FollowsList from "../components/FollowsList";
import { AxiosContext } from "../App";
import { useLocation } from "react-router-dom";
import { useUserContext } from "../components/UserContext";

const Wall = ({
  loggedInUserId,
  loggedInUserName,
  pathUsername,
  handleOpenDoodleEditor,
  doodles,
}) => {
  const { setUserFollows } = useUserContext();

  const axiosInstance = useContext(AxiosContext);
  const [userData, setUserData] = useState(null);
  const [isFollowed, setIsFollowed] = useState(false);

  // Set overlay for 'follows'/'followers' interstitial
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [selectedUsersList, setSelectedUsersList] = useState(null); // 'follows' or 'followers'
  const [usersList, setUsersList] = useState([]);

  const location = useLocation();
  // Determine if the user is viewing his own Wall
  const isViewingOwnWall = pathUsername === loggedInUserName;

  useEffect(() => {
    // Fetch user metadata
    const fetchUserData = async () => {
      try {
        let response;
        if (pathUsername) {
          response = await axiosInstance.get(`/user-meta/${pathUsername}`);
        } else {
          response = await axiosInstance.get("/user-meta/current");
        }
        const userData = response.data[0]; // Assuming the response is an array with one object
        setUserData(userData);
        setIsFollowed(userData.is_followed); // Update isFollowed state
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    fetchUserData();
  }, [pathUsername]);

  const handleFollowChange = (newIsFollowed, userId = null) => {
    // Update the follows count on the logged-in user
    setUserFollows((prevUserFollows) =>
      newIsFollowed ? prevUserFollows + 1 : prevUserFollows - 1
    );
    if (userId) {
      // Update the state for the specific user in the overlay
      setUsersList((prevUsersList) => {
        return prevUsersList.map((user) =>
          user.id === userId ? { ...user, is_followed: newIsFollowed } : user
        );
      });
      if (isViewingOwnWall) {
        // If the user is viewing his own wall and followed or unfollowed somebody in the stitial, then update the follows count
        setUserData((prevUserData) => ({
          ...prevUserData,
          follows_count: newIsFollowed
            ? prevUserData.follows_count + 1
            : prevUserData.follows_count - 1,
        }));
      }
    } else {
      // Update isFollowed state and Followers count
      setIsFollowed(newIsFollowed);
      // Update the user meta of the user whose wall is being viewed
      setUserData((prevUserData) => ({
        ...prevUserData,
        followers_count: newIsFollowed
          ? prevUserData.followers_count + 1
          : prevUserData.followers_count - 1,
      }));
    }
  };

  const openOverlay = (listType) => {
    setSelectedUsersList(listType);
    setIsOverlayOpen(true);
  };

  const closeOverlay = () => {
    setIsOverlayOpen(false);
    setSelectedUsersList(null);
  };

  return (
    <div className="feed">
      {userData && (
        <>
          <UserMeta
            userName={userData.username}
            avatar={userData.avatar}
            followsCount={userData.follows_count}
            followersCount={userData.followers_count}
            userLevel={userData.user_level}
            openOverlay={openOverlay}
          />
          <div className="user-wall-buttons">
            {!isViewingOwnWall && (
              <SendMessageToUser
                userId={userData.id}
                handleOpenDoodleEditor={handleOpenDoodleEditor}
              />
            )}
            {userData.id != loggedInUserId && (
              <Follow
                followed={isFollowed}
                userId={userData.id}
                isInList={false}
                onFollowChange={handleFollowChange}
                className={"user-action-button"}
              />
            )}
            <Link to={`/profile/${userData.username}`} aria-label="Wall">
              <button className="user-action-button">Bio</button>
            </Link>
          </div>
        </>
      )}
      {/* Render overlay if open */}
      {isOverlayOpen && (
        <FollowsList
          listType={selectedUsersList}
          onClose={closeOverlay}
          username={userData.username}
          loggedInUserId={loggedInUserId}
          onFollowChange={handleFollowChange}
          usersList={usersList}
          setUsersList={setUsersList}
        />
      )}
      {doodles.map((doodle) => (
        <Post
          key={doodle.id}
          doodle={doodle}
          // doodles={doodles}
          typeOfDoodle={"doodles"}
          pathUsername={pathUsername}
          loggedInUserName={loggedInUserName}
          isViewingOwnWall={isViewingOwnWall}
          handleOpenDoodleEditor={handleOpenDoodleEditor}
          onFollowChange={handleFollowChange}
        />
      ))}
    </div>
  );
};

export default Wall;
