import React, { useState, useEffect } from "react";
import createAxiosInstance from "../axiosInstance";
import { useUserContext } from "../components/UserContext"; // Import the UserContext hook

const axiosInstance = createAxiosInstance();

const UserSettings = () => {
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);

  const { userName, setUserName } = useUserContext(); // Destructure setUserName from UserContext

  useEffect(() => {
    fetchUserSettings();
  }, []);

  const fetchUserSettings = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axiosInstance.get("/user/settings");
      setUsername(response.data.username);
      setMessage("");
    } catch (error) {
      handleError("Failed to load user settings, please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleError = (customMessage) => {
    setError(customMessage || "Something went wrong, please try again later.");
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage("");
    setError(null);

    try {
      const response = await axiosInstance.post("/user/update-settings/", {
        username,
      });
      setMessage(response.data.message || "Username updated successfully!");

      // Update the username in UserContext
      setUserName(username);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        handleError("This username is already taken.");
      } else {
        handleError();
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="main-content">
      <div className="user-settings">
        <h2 className="user-settings__title">User Settings</h2>
        {error && <p className="user-settings__error">{error}</p>}
        <form onSubmit={handleSubmit} className="user-settings__form">
          <div className="user-settings__input-group">
            <label htmlFor="username" className="user-settings__label">
              Username
            </label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={handleUsernameChange}
              className="user-settings__input"
              placeholder="Enter new username"
              required
            />
          </div>
          <button
            type="submit"
            className="user-settings__button"
            disabled={loading}
          >
            {loading ? "Saving..." : "Save Changes"}
          </button>
          {message && <p className="user-settings__message">{message}</p>}
        </form>
      </div>
    </div>
  );
};

export default UserSettings;
