import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.scss";
import App from "./App";
import { DoodleProvider } from "./components/DoodleContext";
import { UserProvider } from "./components/UserContext";
import { NotificationsProvider } from "./components/NotificationsContext";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <UserProvider>
    <DoodleProvider>
      <NotificationsProvider>
        {/* <React.StrictMode> */}
        <App />
        {/* </React.StrictMode> */}
      </NotificationsProvider>
    </DoodleProvider>
  </UserProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
