import React, { createContext, useContext, useState } from "react";

const UserContext = createContext();

export const useUserContext = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
  // State for authenticated user id
  const [userId, setUserId] = useState(null);

  // State for authenticated user name
  const [userName, setUserName] = useState(null);

  // State for authenticated user avatar
  const [userAvatar, setUserAvatar] = useState(null);

  // State for authenticated user avatar
  const [userFollows, setUserFollows] = useState(null);
  const [userFollowers, setUserFollowers] = useState(null);

  return (
    <UserContext.Provider
      value={{
        userId,
        userName,
        userAvatar,
        userFollows,
        userFollowers,
        setUserId,
        setUserName,
        setUserAvatar,
        setUserFollows,
        setUserFollowers,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
