import React, { useState } from "react";
import UserRating from "./UserRating";
import UserProgressDetails from "./UserProgressDetails";
import RecentScoreIncrements from "./RecentScoreIncrements";

const UserProgressScreen = ({
  userLevel,
  userScore,
  levelCompletionPercent,
  onClose,
  userName,
}) => {
  const [activeSection, setActiveSection] = useState("recentScores"); // Default to "Recent Scores"

  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  return (
    <div className="closable-overlay">
      <div className="close-button" onClick={onClose}></div>
      <div className="overlay-content">
        <UserProgressDetails
          userScore={userScore}
          userLevel={userLevel}
          levelCompletionPercent={levelCompletionPercent}
        />

        {/* Section toggle buttons */}
        <div className="section-toggle">
          <button
            className={`user-action-button ${activeSection === "recentScores" ? "active" : ""}`}
            onClick={() => handleSectionChange("recentScores")}
          >
            Recent Scores
          </button>
          <button
            className={`user-action-button ${activeSection === "userRating" ? "active" : ""}`}
            onClick={() => handleSectionChange("userRating")}
          >
            Rating
          </button>
        </div>

        {/* Conditional Rendering for sections */}
        {activeSection === "recentScores" && <RecentScoreIncrements userName={userName} />}
        {activeSection === "userRating" && <UserRating userName={userName} onClose={onClose} />}
      </div>
    </div>
  );
};

export default UserProgressScreen;