import React, { useEffect, useCallback, useRef } from "react";
import Loader from "../components/Loader";
import { formatEntryDate } from "../utils/dateUtils";
import { useInView } from "react-intersection-observer";
import usePagination from "../hooks/usePagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faBrush, faPalette } from "@fortawesome/free-solid-svg-icons";

const Challenges = () => {
  const initialUrl = "/user/challenges/";
  const { items, setItems, loading, error, loadMore, hasMore } = usePagination(
    initialUrl,
    10
  );
  const isDebouncing = useRef(false);
  const { ref, inView } = useInView({
    triggerOnce: false,
    rootMargin: "200px",
  });

  const debouncedLoadMore = useCallback(() => {
    if (!isDebouncing.current && hasMore && !loading) {
      loadMore();
      isDebouncing.current = true;
      setTimeout(() => {
        isDebouncing.current = false;
      }, 100);
    }
  }, [hasMore, loadMore, loading]);

  useEffect(() => {
    if (inView) {
      debouncedLoadMore();
    }
  }, [inView, debouncedLoadMore]);

  // Group challenges by date (using started_at)
  const groupedChallenges = items.reduce((groups, challenge) => {
    const date = formatEntryDate(challenge.started_at);
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(challenge);
    return groups;
  }, {});

  const renderRewardIcons = (scoreReward, itemRewardType) => {
    return (
      <div className="reward-icons">
        {scoreReward > 0 && (
          <div className="score-reward" title="Score">
            <FontAwesomeIcon icon={faStar} className="reward-icon" />
            <span>{scoreReward}</span>
          </div>
        )}
        {itemRewardType === "tool" && (
          <FontAwesomeIcon
            icon={faBrush}
            className="reward-icon"
            title="Drawing tool"
          />
        )}
        {itemRewardType === "color" && (
          <FontAwesomeIcon
            icon={faPalette}
            className="reward-icon"
            title="Color"
          />
        )}
      </div>
    );
  };

  if (loading && items.length === 0) {
    return <Loader />;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="main-content">
      <div className="feed">
        <div className="challenges-page">
          <h2>Challenges</h2>
          <div className="challenges-list">
            {Object.keys(groupedChallenges).map((date, index) => (
              <div key={index} className="challenge-group">
                <div className="group-date">{date}</div>
                {groupedChallenges[date].map((challenge, challengeIndex) => (
                  <div
                    key={challengeIndex}
                    className={`challenge-item ${
                      !challenge.completed ? "active" : "completed"
                    }`}
                  >
                    {/* Add header container with reward icons and status */}
                    <div className="challenge-header">
                      {renderRewardIcons(
                        challenge.challenge.score_reward,
                        challenge.challenge.reward_item_type
                      )}
                      {!challenge.completed && (
                        <div className="challenge-active-indicator">
                          <span className="challenge-status-text">Opened</span>
                        </div>
                      )}
                      {challenge.completed && (
                        <div className="challenge-completed-indicator">
                          <span className="challenge-status-text">
                            Completed
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="challenge-content">
                      <div className="challenge-name">
                        {challenge.challenge.name}
                      </div>
                      <p className="challenge-description">
                        {challenge.challenge.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            ))}
            {loading && <Loader />}
            {!loading && !hasMore && items.length === 0 && (
              <p>No challenges available.</p>
            )}
            <div ref={ref}></div> {/* Intersection observer target */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Challenges;
