import React, { useState } from "react";
import { Link } from "react-router-dom";
import Like from "./Like";
import Repost from "./Repost";
import Delete from "./Delete";
import Follow from "./Follow";
import UserAvatar from "./UserAvatar";
import SelectAvatar from "../components/SelectAvatar";
import DrawReaction from "./DrawReaction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRetweet } from "@fortawesome/free-solid-svg-icons";
import { useUserContext } from "../components/UserContext";
import CustomContextMenu from "./CustomContextMenu";
import { formatDateTime } from "../utils/dateUtils";

const Doodle = ({
  doodle,
  typeOfDoodle,
  isViewingOwnWall,
  handleOpenDoodleEditor,
  reactionsButtonDisabled,
  onFollowChange,
  isSuitableForAvatar,
}) => {
  const { userId } = useUserContext();
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  return (
    <>
      {doodle.reposter && (
        <div className="repost-info">
          <FontAwesomeIcon icon={faRetweet} className="icon" />
          <Link to={`/${doodle.reposter}`}>{doodle.reposter}</Link> reposted
        </div>
      )}
      {doodle.mood && (
        <div className="mood-info">
          Felt <span className="mood-text">{doodle.mood.toLowerCase()}</span>
        </div>
      )}
      <header className="post-header">
        <div className="user-details">
          <Link to={`/${doodle.belongs_to_user.username}`}>
            <UserAvatar
              avatarUrl={doodle.belongs_to_user.avatar}
              username={doodle.belongs_to_user.username}
              userLevel={doodle.belongs_to_user.user_level}
            />
          </Link>
          <div className="user-info">
            <div className="user-name">
              <Link to={`/${doodle.belongs_to_user.username}`}>
                {doodle.belongs_to_user.username}
              </Link>
            </div>
            <div className="post-date">{formatDateTime(doodle.created)}</div>
          </div>
        </div>
        <CustomContextMenu>
          {doodle.is_owner && (
            <Delete doodle={doodle} typeOfDoodle={typeOfDoodle} />
          )}
          {doodle.belongs_to_user.id !== userId ? (
            <Follow
              followed={null}
              userId={doodle.belongs_to_user.id}
              isInList={false}
              onFollowChange={onFollowChange}
              className={"menu-item"}
            />
          ) : null}
          {isSuitableForAvatar && isViewingOwnWall && (
            <>
              <div className="menu-item">
                <SelectAvatar doodle={doodle} />
              </div>
              <div
                className="menu-item"
                onClick={() =>
                  handleOpenDoodleEditor(
                    "addProfileEntry",
                    {
                      id: null,
                      is_avatars: true,
                    },
                    doodle.id
                  )
                }
              >
                Draw over
              </div>
            </>
          )}
        </CustomContextMenu>
      </header>
      <div className={`post-image ${isImageLoaded ? "" : "loading"}`}>
        <img
          src={doodle.image}
          alt="Doodle"
          onLoad={handleImageLoad}
          className={isImageLoaded ? "loaded" : ""}
        />
      </div>
      <footer className="post-footer">
        <Like
          doodleId={doodle.id}
          liked={doodle.liked}
          typeOfDoodle={typeOfDoodle}
          likesCounter={doodle.likes_counter}
        />
        <DrawReaction
          doodle={doodle}
          reactionsButtonDisabled={reactionsButtonDisabled}
          handleOpenDoodleEditor={handleOpenDoodleEditor}
        />
        <Repost
          doodle={doodle}
          reposted={doodle.reposted}
          repostsCounter={doodle.reposts_counter}
          isViewingOwnWall={isViewingOwnWall}
          typeOfDoodle={typeOfDoodle}
          userOwnsDoodle={doodle.is_owner}
        />
      </footer>
    </>
  );
};

export default Doodle;
