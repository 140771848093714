import React, { useState, useEffect, useRef } from "react";
import { Stage, Layer, Line, Image as KonvaImage } from "react-konva";
import useImage from "use-image";

const DrawingArea = ({
  lines,
  setLines,
  selectedTool,
  selectedColor,
  config,
  colorQuantities,
  setColorQuantities,
  colorSpendFactor,
  exactQuantities,
  changedPixels,
  allChangedPixels,
  colorHistory,
  setColorHistory,
  toolDurabilities,
  setToolDurabilities,
  durabilitySpendFactor,
  exactDurabilities,
  toolHistory,
  setToolHistory,
  isDrawing,
  setIsDrawing,
  brushTextureSrc,
  existingDoodleImage, // New prop for loading existing doodle image
}) => {
  const [brushTexture] = useImage(brushTextureSrc);
  const [backgroundImage] = useImage(existingDoodleImage); // Load the existing doodle image

  const handleStart = (e) => {
    const tool = config.tools[selectedTool];
    let colorKey;

    if (!tool.has_palette) {
      colorKey = tool.default_color.color;
    } else {
      colorKey = Object.keys(config.colors).find(
        (key) => config.colors[key].color === selectedColor
      );
    }

    if (colorQuantities[colorKey] === 0 || toolDurabilities[selectedTool] === 0) {
      return; // Prevent starting a new stroke if the color quantity or tool durability is zero
    }

    setIsDrawing(true);
    const { x, y } = e.target.getStage().getPointerPosition();
    const thickness = tool.thickness;
    setLines((prevLines) => [
      ...prevLines,
      {
        tool: selectedTool,
        points: [x, y],
        stroke: selectedColor,
        strokeWidth: thickness,
        strokePatternImage: selectedTool === "textured" ? brushTexture : null,
        colorKey,
        thickness,
        initialQuantity: exactQuantities.current[colorKey],
        initialDurability: exactDurabilities.current[selectedTool],
        pixelCount: 0,
        durabilitySpend: 0,
      },
    ]);
    changedPixels.current[colorKey].clear(); // Clear the set for new stroke
  };

  const handleMove = (e) => {
    if (!isDrawing) return;
    const { x, y } = e.target.getStage().getPointerPosition();
    setLines((prevLines) => {
      const lastLine = prevLines[prevLines.length - 1];
      const prevPoint = lastLine.points.slice(-2);
      const newPoint = [x, y];
      lastLine.points = lastLine.points.concat(newPoint);

      const newPixels = trackChangedPixels(lastLine.points, lastLine.thickness);
      const colorKey = lastLine.colorKey;

      // Handle color spend
      if (exactQuantities.current[colorKey] !== Infinity) {
        newPixels.forEach((pixel) => {
          if (!allChangedPixels.current[colorKey].has(pixel)) {
            allChangedPixels.current[colorKey].add(pixel);
            changedPixels.current[colorKey].add(pixel);
            exactQuantities.current[colorKey] = Math.max(
              exactQuantities.current[colorKey] - colorSpendFactor,
              0
            );
          }
        });

        if (exactQuantities.current[colorKey] === 0) {
          setIsDrawing(false); // Stop the stroke when color quantity reaches zero
        }

        setColorQuantities((prevQuantities) => ({
          ...prevQuantities,
          [colorKey]: Math.round(exactQuantities.current[colorKey]),
        }));
        lastLine.pixelCount += newPixels.size;
      }

      // Handle durability spend
      if (exactDurabilities.current[selectedTool] !== Infinity) {
        const distance = calculateDistance(prevPoint, newPoint);
        lastLine.durabilitySpend += distance * durabilitySpendFactor;
        exactDurabilities.current[selectedTool] = Math.max(
          exactDurabilities.current[selectedTool] - distance * durabilitySpendFactor,
          0
        );

        if (exactDurabilities.current[selectedTool] === 0) {
          setIsDrawing(false); // Stop the stroke when tool durability reaches zero
        }

        setToolDurabilities((prevDurabilities) => ({
          ...prevDurabilities,
          [selectedTool]: Math.round(exactDurabilities.current[selectedTool]),
        }));
      }

      return prevLines.concat();
    });
  };

  const handleEnd = () => {
    setIsDrawing(false);
    const lastLine = lines[lines.length - 1];
    const colorKey = lastLine.colorKey;
    const toolKey = lastLine.tool;

    if (exactQuantities.current[colorKey] !== Infinity) {
      const colorUsed = changedPixels.current[colorKey].size * colorSpendFactor;
      setColorHistory((prevHistory) => [
        ...prevHistory,
        { colorKey, colorUsed, initialQuantity: lastLine.initialQuantity },
      ]);
      changedPixels.current[colorKey].clear();
    }

    if (exactDurabilities.current[toolKey] !== Infinity) {
      setToolHistory((prevHistory) => [
        ...prevHistory,
        {
          toolKey,
          durabilityUsed: lastLine.durabilitySpend,
          initialDurability: lastLine.initialDurability,
        },
      ]);
    }
  };

  const trackChangedPixels = (points, thickness) => {
    const newPixels = new Set();
    for (let i = 0; i < points.length - 2; i += 2) {
      const x = points[i];
      const y = points[i + 1];
      for (let dx = -thickness / 2; dx <= thickness / 2; dx++) {
        for (let dy = -thickness / 2; dy <= thickness / 2; dy++) {
          newPixels.add(`${Math.round(x + dx)},${Math.round(y + dy)}`);
        }
      }
    }
    return newPixels;
  };

  const calculateDistance = (pointA, pointB) => {
    const [x1, y1] = pointA;
    const [x2, y2] = pointB;
    return Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
  };

  useEffect(() => {
    const handleWindowMouseUp = () => {
      if (isDrawing) {
        handleEnd();
      }
    };

    window.addEventListener("mouseup", handleWindowMouseUp);

    return () => {
      window.removeEventListener("mouseup", handleWindowMouseUp);
    };
  }, [isDrawing]);

  return (
    <Stage
      width={800}
      height={600}
      onMouseDown={handleStart}
      onMouseMove={handleMove}
      onMouseUp={handleEnd}
      onTouchStart={handleStart}
      onTouchMove={handleMove}
      onTouchEnd={handleEnd}
    >
      <Layer>
        {backgroundImage && ( // If there's an existing doodle image, display it
          <KonvaImage image={backgroundImage} x={0} y={0} width={800} height={600} />
        )}
        {lines.map((line, i) => (
          <Line
            key={i}
            points={line.points}
            stroke={line.stroke}
            strokeWidth={line.strokeWidth}
            lineCap="round"
            lineJoin="round"
            globalCompositeOperation={
              line.tool === "eraser" ? "destination-out" : "source-over"
            }
            strokePatternImage={line.tool === "textured" ? brushTexture : null}
            strokePatternScale={{
              x: 0.1,
              y: 0.1,
            }}
          />
        ))}
      </Layer>
    </Stage>
  );
};

export default DrawingArea;