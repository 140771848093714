import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import CustomContextMenu from "./CustomContextMenu"; // Assuming you already have CustomContextMenu component

const DrawReaction = ({
  doodle,
  handleOpenDoodleEditor,
  reactionsButtonDisabled,
}) => {
  return (
    <CustomContextMenu
      triggerElement={
        <button
          className={`button ${reactionsButtonDisabled ? "inactive" : "active"}`}
          disabled={reactionsButtonDisabled} // Disable the button if needed
        >
          <FontAwesomeIcon icon={faComment} className="icon" />
          <span className="counter">{doodle.reactions_counter}</span>
        </button>
      }
    >
      <div
        className="menu-item"
        onClick={() => handleOpenDoodleEditor("postReaction", doodle.id)}
      >
        New drawing
      </div>
      <div
        className="menu-item"
        onClick={() =>
          handleOpenDoodleEditor(
            "postReaction",
            doodle.id,
            doodle.id
          )
        }
      >
        Draw over
      </div>
    </CustomContextMenu>
  );
};

export default DrawReaction;