// moodActions.js

export const logMood = async (axiosInstance, mood) => {
  try {
    const response = await axiosInstance.post('/log-mood/', { mood });
    return response.data.id; // Return the id of the newly created mood entry
  } catch (error) {
    console.error("Failed to log mood:", error);
    return null;
  }
};
