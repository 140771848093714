// UserAvatar.js

import React from "react";
import PropTypes from "prop-types";

const UserAvatar = ({ avatarUrl, username, userLevel }) => {
  return (
    <div className="user-avatar-container">
      <div className="user-avatar">
        {avatarUrl ? (
          <img src={avatarUrl} alt={`${username}'s avatar`} />
        ) : (
          <div className="avatar-initial">
            {username.charAt(0).toUpperCase()}
          </div>
        )}
      </div>
      {userLevel ? <div className="user-level">{userLevel}</div> : null}
    </div>
  );
};

UserAvatar.propTypes = {
  avatarUrl: PropTypes.string,
  username: PropTypes.string.isRequired,
};

export default UserAvatar;
