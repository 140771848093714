// CustomContextMenu.js
import React, { useState, useRef, useEffect, useCallback } from "react";
import { usePopper } from "react-popper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";

const CustomContextMenu = ({ children, triggerElement }) => {
  const [visible, setVisible] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-end",
  });

  const menuRef = useRef();

  const setRefs = useCallback(
    (node) => {
      menuRef.current = node;
      setPopperElement(node);
    },
    [setPopperElement]
  );

  const handleClick = () => {
    setVisible(!visible);
  };

  const handleOutsideClick = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setVisible(false);
    }
  };

  useEffect(() => {
    if (visible) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [visible]);

  // Filter out falsy children
  const validChildren = React.Children.toArray(children).filter(Boolean);

  return (
    <>
      {/* Use the passed trigger element */}
      <div ref={setReferenceElement} onClick={handleClick}>
        {triggerElement ? (
          React.cloneElement(triggerElement, { onClick: handleClick })
        ) : (
          <div className="options-button" onClick={handleClick}>
            <FontAwesomeIcon icon={faEllipsis} />
          </div>
        )}
      </div>

      {visible && (
        <div
          ref={setRefs}
          style={styles.popper}
          {...attributes.popper}
          className="context-menu"
        >
          {React.Children.map(validChildren, (child) => (
            <div
              onClick={() => {
                setVisible(false);
              }}
            >
              {child}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default CustomContextMenu;