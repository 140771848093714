import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faMessage,
  faBell,
  faBurst,
} from "@fortawesome/free-solid-svg-icons";
import { useNotifications } from "./NotificationsContext";

const LowerNav = () => {
  const { notifications } = useNotifications();

  return (
    <nav className="lower-nav">
      <Link to="/" aria-label="Feed" className="nav-button">
        <div className="icon-container">
          <FontAwesomeIcon icon={faHouse} className="icon" />
        </div>
      </Link>
      <Link
        to="/notifications"
        aria-label="Notifications"
        className="nav-button"
      >
        <div className="icon-container">
          <FontAwesomeIcon icon={faBell} className="icon" />
          {notifications.unreadNotifications > 0 && (
            <div className="notifications-counter">
              {notifications.unreadNotifications}
            </div>
          )}
        </div>
      </Link>
      <Link to="/challenges" aria-label="Challenges" className="nav-button">
        <div className="icon-container">
          <FontAwesomeIcon icon={faBurst} className="icon" />
          {notifications.openedChallanges > 0 && (
            <div className="notifications-counter">
              {notifications.openedChallanges}
            </div>
          )}
        </div>
      </Link>
      <Link to="/chats" aria-label="Chats" className="nav-button">
        <div className="icon-container">
          <FontAwesomeIcon icon={faMessage} className="icon" />
          {notifications.unreadMessages > 0 && (
            <div className="notifications-counter">
              {notifications.unreadMessages}
            </div>
          )}
        </div>
      </Link>
    </nav>
  );
};

export default LowerNav;
