// DoodleContext.js
// Used for Context Provider to provide states, state setters, updater functions to handle actions with Doodles - likes, reposts, sending reactions, deleting posts, etc.

import React, { createContext, useContext, useState } from "react";
import { useUserContext } from "../components/UserContext";

const DoodleContext = createContext();

export const useDoodleContext = () => {
  return useContext(DoodleContext);
};

export const DoodleProvider = ({ children }) => {
  const { userAvatar, setUserAvatar } = useUserContext();

  // States for doodles on Wall and Feed
  const [doodles, setDoodles] = useState([]);

  // States for doodles on Reactions screen
  const [doodleReactedTo, setDoodleReactedTo] = useState([]);
  const [reactions, setReactions] = useState([]);

  // States for Reactions modal
  const [isReactionsOpen, setIsReactionsOpen] = useState(false);
  const [postIdForReactionsModal, setPostIdForReactionsModal] = useState(null); // The original post for which the reactions are being viewed

  // States for Chats page
  const [chats, setChats] = useState([]);
  const [isChatOverlayOpen, setIsChatOverlayOpen] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);

  // States for Profile page
  // Username for which to fetch entries for the Profile page
  const [usernameForProfilePage, setUsernameForProfilePage] = useState(null);
  // Profile page - state that holds full list of profile entries types with nested "latest" Doodles
  const [profileEntriesPage, setProfileEntriesPage] = useState([]);
  // Profile feed - state that holds one particular profile entries type and nested Doodles to view in a separate overlay
  const [isProfileEntriesFeedOpen, setIsProfileEntriesFeedOpen] =
    useState(false);
  const [profileEntriesFeed, setProfileEntriesFeed] = useState([]);
  // Profile entry type ID for which to open the Profile feed
  const [profileEntryTypeObject, setProfileEntryTypeObject] = useState(null);

  // Functions for handling Reactions modal
  const openReactionsModal = (doodle) => {
    setPostIdForReactionsModal(doodle.id);
    setDoodleReactedTo(doodle);
    setIsReactionsOpen(true);
  };

  const closeReactionsModal = () => {
    setIsReactionsOpen(false);
  };

  // Helper function to update likes
  const updateLikesHelper = (items, itemId, liked) => {
    return items.map((item) => {
      if (item.id === itemId) {
        return {
          ...item,
          likes_counter: liked
            ? item.likes_counter + 1
            : item.likes_counter - 1,
          liked,
        };
      }
      return item;
    });
  };

  // Updater for likes counters
  const onUpdateLikes = (doodleId, liked, type = "doodles") => {
    switch (type) {
      case "doodles":
        const updatedDoodles = updateLikesHelper(doodles, doodleId, liked);
        setDoodles(updatedDoodles);
        break;

      // If the reactions screen is opened
      case "reactions":
        // Update likes for the main post
        const updatedDoodleReactedTo = updateLikesHelper(
          [doodleReactedTo],
          doodleReactedTo.id,
          liked
        )[0];
        setDoodleReactedTo(updatedDoodleReactedTo);

        // Update likes for the reactions feed under this main post
        const updatedReactions = updateLikesHelper(reactions, doodleId, liked);
        setReactions(updatedReactions);

        // Update likes for a copy of the main post that may be visible on the page behind the reactions screen
        const updatedDoodlesOnBackgroundPage = updateLikesHelper(
          doodles,
          doodleId,
          liked
        );
        setDoodles(updatedDoodlesOnBackgroundPage);
        break;

      case "profileEntries":
        const updatedProfileEntries = updateLikesHelper(
          profileEntriesFeed,
          doodleId,
          liked
        );

        setProfileEntriesFeed(updatedProfileEntries);
        break;

      default:
        break;
    }
  };

  // Updater for doodle deletes
  const onDoodleDelete = (deletedDoodle, typeOfDoodle) => {
    switch (typeOfDoodle) {
      case "doodles":
        // Filter out the deleted doodle from the current doodles array
        const updatedDoodles = doodles.filter(
          (doodle) => doodle.id !== deletedDoodle.id
        );
        // Set the updated array of doodles
        setDoodles(updatedDoodles);
        break;

      case "reactions":
        // Check if the deleted doodle is the main post
        if (deletedDoodle.id === postIdForReactionsModal) {
          // Close the reactions modal
          closeReactionsModal();

          // Filter out the deleted doodle from the current doodles array
          const updatedDoodles = doodles.filter(
            (doodle) => doodle.id !== deletedDoodle.id
          );

          // Set the updated array of doodles
          setDoodles(updatedDoodles);
        } else {
          // Filter out the deleted doodle from the reactions array
          const updatedReactions = reactions.filter(
            (reaction) => reaction.id !== deletedDoodle.id
          );
          // Set the updated array for reactions
          setReactions(updatedReactions);

          // Update the doodles state to remove the deletedDoodle from reactions object
          const updatedDoodles = doodles.map((doodle) => {
            if (doodle.reactions && doodle.reactions.length > 0) {
              const updatedDoodleReactions = doodle.reactions.filter(
                (reaction) => reaction.id !== deletedDoodle.id
              );
              return {
                ...doodle,
                reactions: updatedDoodleReactions,
                reactions_counter: doodle.reactions_counter - 1,
              };
            }
            return doodle;
          });
          setDoodles(updatedDoodles);
        }
        break;

      case "messages":
        // Filter out the deleted message from the chatMessages
        const updatedMessages = chatMessages.messages.filter(
          (message) => message.id !== deletedDoodle.id
        );
        const updatedChatMessages = {
          ...chatMessages,
          messages: updatedMessages,
        };
        setChatMessages(updatedChatMessages);

        // Check if the deleted message is the last_message of any chat
        const updatedChats = chats.map((chat) => {
          if (chat.last_message && chat.last_message.id === deletedDoodle.id) {
            // Find the index of the deleted message in chatMessages
            const deletedMessageIndex = chatMessages.messages.findIndex(
              (message) => message.id === deletedDoodle.id
            );

            if (deletedMessageIndex !== -1 && deletedMessageIndex > 0) {
              // Get the previous message
              const prevMessage =
                chatMessages.messages[deletedMessageIndex - 1];

              // Update last_message for the matched chat with the previous message
              return {
                ...chat,
                last_message: prevMessage,
              };
            }
          }
          return chat;
        });

        setChats(updatedChats);

        break;

      case "profileEntries":
        // Helper function to update doodles
        const updateDoodles = (entries, deletedDoodle) => {
          return entries.map((entry) => {
            // Check if the deleted doodle is being used as an avatar
            const isUsingDeletedDoodle =
              entry.is_avatars && userAvatar.id === deletedDoodle.id;

            // Filter out the deleted doodle
            const updatedDoodles = entry.doodles.filter(
              (doodle) => doodle.id !== deletedDoodle.id
            );

            // Set userAvatar to the thumbnail of the newest doodle or null
            if (isUsingDeletedDoodle && updatedDoodles.length > 0) {
              const newestDoodle = updatedDoodles.sort(
                (a, b) => new Date(b.created) - new Date(a.created)
              )[0];
              setUserAvatar(newestDoodle);
            } else if (isUsingDeletedDoodle) {
              setUserAvatar(null);
            }

            // If entry has no doodles left, keep it and return the updated entry
            if (updatedDoodles.length === 0) {
              return {
                ...entry,
                doodles: updatedDoodles,
              };
            }

            return {
              ...entry,
              doodles: updatedDoodles,
            };
          });
        };

        // Update Profile feed
        const updatedProfileEntriesFeedDelete = profileEntriesFeed.filter(
          (entry) => entry.id !== deletedDoodle.id
        );
        // Set the updated array for reactions
        setProfileEntriesFeed(updatedProfileEntriesFeedDelete);

        // Update Profile page in the background
        const updatedProfileEntriesPageDelete = updateDoodles(
          profileEntriesPage,
          deletedDoodle
        );
        setProfileEntriesPage(updatedProfileEntriesPageDelete);

        // Close ProfileEntriesFeed if it's opened and if there are no more doodles in the feed of this entry
        if (
          isProfileEntriesFeedOpen &&
          updatedProfileEntriesFeedDelete?.length === 0
        ) {
          setIsProfileEntriesFeedOpen(false);
        }
        break;

      default:
        break;
    }
  };

  // Function to update doodles after submission of a new doodle
  const onDoodleSubmit = (newDoodle, intention, context = null) => {
    switch (intention) {
      case "postOnMyWall":
        setDoodles([newDoodle, ...doodles]);
        break;

      case "drawReasonForMood":
        if (context.shareOnWall) {
          setDoodles([newDoodle, ...doodles]);
        }
        break;

      case "postReaction":
        if (context) {
          // Find the post in currentDoodles based on postId
          const updatedDoodles = doodles.map((doodle) => {
            if (doodle.id === context) {
              // Update comment previews for the post
              const updatedReactions = [newDoodle, ...doodle.reactions];
              return {
                ...doodle,
                reactions: updatedReactions,
                reactions_counter: doodle.reactions_counter + 1,
              };
            }
            return doodle;
          });
          setDoodles(updatedDoodles);
        }
        break;

      case "sendMessageToChat":
        // Update messages
        const updatedChatMessages = [...chatMessages, newDoodle];
        setChatMessages(updatedChatMessages);

        // Update chats in the background
        if (context) {
          const updatedChats = chats
            .map((chat) => {
              if (chat.id === context) {
                // Update last_message for the matched chat
                return {
                  ...chat,
                  last_message: newDoodle,
                };
              }
              return chat;
            })
            .sort((a, b) => (a.id === context ? -1 : b.id === context ? 1 : 0)); // Move updated chat to the top
          setChats(updatedChats);
        }

        break;

      case "addProfileEntry":
        // Find the correct ProfileEntryType object
        const updatedProfileEntriesPage = profileEntriesPage.map(
          (entryType) => {
            if (entryType.id === context.id) {
              // Add the newDoodle to the doodles array
              const updatedDoodles = [newDoodle, ...entryType.doodles];

              // Return a new object with updated doodles
              return {
                ...entryType,
                doodles: updatedDoodles,
              };
            }
            return entryType; // Return unchanged if not the target ProfileEntryType
          }
        );

        // Update the profileEntries state
        setProfileEntriesPage(updatedProfileEntriesPage);

        // If the new doodle is avatar, then set this avatar right away
        if (context.is_avatars) {
          setUserAvatar(newDoodle);
        }

        break;

      default:
        // Handle other cases or provide a default action
        break;
    }
  };

  // Updater for reposts and unreposts
  const onUpdateReposts = (
    repostedDoodle,
    reposted,
    isViewingOwnWall,
    typeOfDoodle
  ) => {
    // If user's viewing his own wall and something is being unreposted, remove the unreposted Doodle as if it has been deleted
    if (isViewingOwnWall && !reposted) {
      const doodlesExcludingDeletedRepost = doodles.filter(
        (doodle) => doodle.id !== repostedDoodle.id
      );
      setDoodles(doodlesExcludingDeletedRepost);
    }
    switch (typeOfDoodle) {
      // If the user changed the repost state of something on a regular page, then just update counters and button state
      case "doodles":
        setDoodles((prevDoodles) => {
          const updatedDoodles = prevDoodles.map((doodle) => {
            if (doodle.id === repostedDoodle.id) {
              return {
                ...doodle,
                reposts_counter: reposted
                  ? doodle.reposts_counter + 1
                  : doodle.reposts_counter - 1,
                reposted,
              };
            }
            return doodle;
          });
          return updatedDoodles;
        });
        break;

      // If action happened on the reactions screen, then (1) update reposts counter on the reactions screen (search on the main post and in the reactions feed), and also in the doodles on the background
      case "reactions":
        // Update main post that is being reacted to
        const updatedMainDoodle = { ...doodleReactedTo }; // Create a shallow copy of the object
        if (updatedMainDoodle.id === repostedDoodle.id) {
          updatedMainDoodle.reposts_counter = reposted
            ? updatedMainDoodle.reposts_counter + 1
            : updatedMainDoodle.reposts_counter - 1;
          updatedMainDoodle.reposted = reposted;
        }
        setDoodleReactedTo(updatedMainDoodle);

        // Update posts inside of reactions feed
        const updatedReactions = reactions.map((doodle) => {
          if (doodle.id === repostedDoodle.id) {
            return {
              ...doodle,
              reposts_counter: reposted
                ? doodle.reposts_counter + 1
                : doodle.reposts_counter - 1,
              reposted,
            };
          }
          return doodle;
        });
        setReactions(updatedReactions);

        // Update doodles feed on the page in the background
        setDoodles((prevDoodles) => {
          const updatedDoodles = prevDoodles.map((doodle) => {
            if (doodle.id === repostedDoodle.id) {
              return {
                ...doodle,
                reposts_counter: reposted
                  ? doodle.reposts_counter + 1
                  : doodle.reposts_counter - 1,
                reposted,
              };
            }
            return doodle;
          });
          return updatedDoodles;
        });

        break;

      case "profileEntries":
        const updatedProfileEntries = profileEntriesFeed.map((doodle) => {
          if (doodle.id === repostedDoodle.id) {
            return {
              ...doodle,
              reposts_counter: reposted
                ? doodle.reposts_counter + 1
                : doodle.reposts_counter - 1,
              reposted,
            };
          }
          return doodle;
        });
        setProfileEntriesFeed(updatedProfileEntries);
        break;

      default:
        break;
    }
  };

  // ... other updater functions

  return (
    <DoodleContext.Provider
      value={{
        doodles,
        isReactionsOpen,
        postIdForReactionsModal,
        doodleReactedTo,
        reactions,
        chats,
        isChatOverlayOpen,
        chatMessages,
        usernameForProfilePage,
        profileEntriesPage,
        isProfileEntriesFeedOpen,
        profileEntriesFeed,
        profileEntryTypeObject,
        setDoodles,
        setIsReactionsOpen,
        setPostIdForReactionsModal,
        setDoodleReactedTo,
        setReactions,
        openReactionsModal,
        closeReactionsModal,
        onUpdateLikes,
        onUpdateReposts,
        onDoodleDelete,
        onDoodleSubmit,
        setChats,
        setIsChatOverlayOpen,
        setChatMessages,
        setUsernameForProfilePage,
        setProfileEntriesPage,
        setIsProfileEntriesFeedOpen,
        setProfileEntriesFeed,
        setProfileEntryTypeObject,
      }}
    >
      {children}
    </DoodleContext.Provider>
  );
};
