// Header.js

import React from "react";
import { Link, useLocation } from "react-router-dom";
import UserAvatar from "./UserAvatar";
import { useUserContext } from "../components/UserContext";

function Header({ isAuthenticated, toggleSideMenu }) {
  const location = useLocation();
  const { userName, userAvatar } = useUserContext();

  return (
    <header className="header">
      {isAuthenticated ? (
        <>
          {/* Upper navigation bar */}
          <div className="upper-nav">
            <div onClick={toggleSideMenu}>
              <UserAvatar
                avatarUrl={userAvatar?.thumbnail}
                username={userName}
              />
            </div>
            {/* Service name in the center */}
            <div className="logo logo-gradient">Cavegram</div>
          </div>
        </>
      ) : null}
    </header>
  );
}

export default Header;
