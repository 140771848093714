import { parseISO, format, getYear, isToday, isThisWeek } from "date-fns";

export function formatDateTime(dateString) {
  const date = parseISO(dateString);
  const currentYear = getYear(new Date());
  const messageYear = getYear(date);

  if (isToday(date)) {
    return format(date, "HH:mm"); // Only time in 24 hours format
  } else if (isThisWeek(date)) {
    return format(date, "EE"); // Day of the week
  } else if (messageYear === currentYear) {
    return format(date, "d MMM"); // Date in "1 Dec" format
  } else {
    return format(date, "MM.dd.yyyy"); // Date in "12.24.2022" format
  }
}

export function formatEntryDate(dateString) {
  const date = parseISO(dateString);
  const currentYear = getYear(new Date());
  const messageYear = getYear(date);

  if (isThisWeek(date)) {
    return format(date, "EE"); // Day of the week
  } else if (messageYear === currentYear) {
    return format(date, "d MMM"); // Date in "1 Dec" format
  } else {
    return format(date, "MM.dd.yyyy"); // Date in "12.24.2022" format
  }
}

export function formatTime(dateString) {
  try {
    const date = parseISO(dateString);
    return format(date, "HH:mm");
  } catch (error) {
    console.error("Error parsing date:", error);
    return ""; // Return an empty string or a fallback value if parsing fails
  }
}