// AddProfileEntry.js

import React from "react";

const AddProfileEntry = ({ entryType, handleOpenDoodleEditor }) => {
  return (
    <div
      className="add-button"
      onClick={() =>
        handleOpenDoodleEditor("addProfileEntry", {
          id: entryType.id,
          is_avatars: entryType.is_avatars,
        })
      }
    >
      +
    </div>
  );
};

export default AddProfileEntry;
