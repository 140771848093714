import ReactGA from "react-ga4";

const GA_TRACKING_ID = "G-W3JYXFFMYC";

const initializeAnalytics = () => {
  if (process.env.NODE_ENV === "production") {
    ReactGA.initialize(GA_TRACKING_ID);
  }
};

const trackPageView = (page) => {
  ReactGA.send({ hitType: "pageview", page });
};

const trackEvent = (category, action, label) => {
  ReactGA.event({
    category,
    action,
    label,
  });
};

// Export functions for use in other parts of the app
export { initializeAnalytics, trackPageView, trackEvent };
