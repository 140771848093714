import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { trackPageView } from './Analytics';

const useAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    const page = location.pathname + location.search;
    trackPageView(page);
  }, [location]);
};

export default useAnalytics;
