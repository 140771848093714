import React, { useEffect, useState, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import createAxiosInstance from "../axiosInstance";
import Loader from "../components/Loader";
import UserAvatar from "../components/UserAvatar";
import { formatEntryDate, formatDateTime } from "../utils/dateUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faUser,
  faRetweet,
  faComment,
  faPencil,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import usePagination from "../hooks/usePagination";
import { useInView } from "react-intersection-observer";
import { useNotifications } from "../components/NotificationsContext";

const NotificationsPage = () => {
  const initialUrl = "/notifications/";
  const { items, setItems, loading, error, loadMore, hasMore } = usePagination(
    initialUrl,
    10
  );
  const [notifications, setNotifications] = useState([]);
  const { incrementUnreadCounter } = useNotifications();
  const axiosInstance = createAxiosInstance();
  const [notificationsUpdated, setNotificationsUpdated] = useState(false);
  const isDebouncing = useRef(false);

  // Intersection observer to trigger loading more items
  const { ref, inView } = useInView({
    triggerOnce: false,
    rootMargin: "200px",
  });

  const debouncedLoadMore = useCallback(() => {
    if (!isDebouncing.current && hasMore && !loading) {
      loadMore();
      isDebouncing.current = true;
      setTimeout(() => {
        isDebouncing.current = false;
      }, 100);
    }
  }, [hasMore, loadMore, loading]);

  useEffect(() => {
    if (inView) {
      debouncedLoadMore();
    }
  }, [inView, debouncedLoadMore]);

  const renderNotificationIcon = (type) => {
    switch (type) {
      case "Like":
        return <FontAwesomeIcon icon={faHeart} className="notification-icon" />;
      case "Repost":
        return (
          <FontAwesomeIcon icon={faRetweet} className="notification-icon" />
        );
      case "Reaction":
        return (
          <FontAwesomeIcon icon={faComment} className="notification-icon" />
        );
      case "Follow":
        return <FontAwesomeIcon icon={faUser} className="notification-icon" />;
      case "Item":
        return <FontAwesomeIcon icon={faPencil} className="notification-icon" />;
      case "Invitation": // New case for Invitation
        return (
          <FontAwesomeIcon icon={faUserPlus} className="notification-icon" />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    setNotifications(items);
    setNotificationsUpdated(true); // Indicate that notifications have been updated
  }, [items]);

  useEffect(() => {
    // If notifications have been updated, wait for 2 seconds before marking as read
    if (notificationsUpdated) {
      const timeoutId = setTimeout(() => {
        markNotificationsAsRead(notifications);
        setNotificationsUpdated(false); // Reset notificationsUpdated state
      }, 2000);

      // Clear timeout on component unmount or when notifications change
      return () => clearTimeout(timeoutId);
    }
  }, [notificationsUpdated, notifications]);

  // Function to mark notifications as read
  const markNotificationsAsRead = async (notifications) => {
    const unreadNotifications = notifications.filter(
      (notification) => !notification.is_read
    );
    const notificationIds = unreadNotifications.map(
      (notification) => notification.id
    );

    if (notificationIds.length === 0) {
      return;
    }

    try {
      // Query the endpoint to mark notifications as read
      await axiosInstance.post("/notifications/mark-read/", {
        ids: notificationIds,
      });

      // Update the notifications state and items state
      const updatedNotifications = notifications.map((notification) =>
        notificationIds.includes(notification.id)
          ? { ...notification, is_read: true }
          : notification
      );

      setNotifications(updatedNotifications);
      setItems(updatedNotifications);

      // Decrease the unread notifications counter
      incrementUnreadCounter(-notificationIds.length, "unreadNotifications");
    } catch (error) {
      console.error("Failed to mark notifications as read", error);
    }
  };

  // Group notifications by day
  const groupedNotifications = notifications.reduce((groups, notification) => {
    const date = formatEntryDate(notification.created); // Group by formatted date
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(notification);
    return groups;
  }, {});

  return (
    <div className="main-content">
      <div className="feed">
        <div className="notifications-page">
          <div className="notifications-list">
            {Object.keys(groupedNotifications).map((date, index) => (
              <div key={index} className="notification-section">
                <div className="section-date">{date}</div>
                {groupedNotifications[date].map((notification, notificationIndex) => (
                  <div
                    key={notificationIndex}
                    className={`notification-item ${
                      notification.is_read ? "" : "unread"
                    }`}
                  >
                    <div className="notification-date">
                      {formatDateTime(notification.created)}
                    </div>
                    {renderNotificationIcon(notification.notification_type)}
                    <div className="notification-content">
                      {notification.message ? (
                        <div className="notification-message">
                          {notification.message}
                        </div>
                      ) : (
                        <>
                          <Link to={`/${notification.related_user.username}`}>
                            <UserAvatar
                              avatarUrl={notification.related_user.avatar}
                              username={notification.related_user.username}
                              userLevel={notification.related_user.user_level}
                            />
                          </Link>
                          <div className="notification-text">
                            <Link
                              className="user-name"
                              to={`/${notification.related_user.username}`}
                            >
                              {notification.related_user.username}
                            </Link>
                            <div className="notification-description">
                              {notification.notification_type === "Follow"
                                ? "followed you"
                                : notification.notification_type === "Like"
                                ? "liked your post"
                                : notification.notification_type === "Repost"
                                ? "reposted your post"
                                : notification.notification_type === "Reaction"
                                ? "reacted to your post"
                                : notification.notification_type === "Invitation"
                                ? "got invited by you"
                                : ""}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    {notification.related_doodle?.thumbnail && (
                      <img
                        src={notification.related_doodle.thumbnail}
                        alt="doodle-thumbnail"
                        className="doodle-thumbnail"
                      />
                    )}
                  </div>
                ))}
              </div>
            ))}
            {loading && <Loader />}
            {!loading && !hasMore && notifications.length === 0 && (
              <p>No notifications</p>
            )}
            {error && <p>{error}</p>}
            <div ref={ref}></div> {/* Intersection observer target */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationsPage;