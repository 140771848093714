// UserRatingItem.js
import React from "react";
import UserAvatar from "../UserAvatar";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCheck, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import Follow from "../Follow";

const UserRatingItem = ({
  user,
  rank,
  isCurrentUser,
  onClose,
  onFollowChange,
}) => {
  return (
    <div className={"user-item-container"}>
      <div className="user-rank">{rank}</div>
      <div className="user-score">{user.user_score}</div>
      <div className={`user-item ${isCurrentUser ? "current-user" : ""}`}>
        <UserAvatar
          avatarUrl={user.avatar}
          username={user.username}
          userLevel={user.user_level}
        />
        <div className="user-info">
          <div className="user-name">
            <Link to={`/${user.username}`} onClick={onClose}>
              {user.username}
            </Link>
          </div>
          <div className="user-stats">
            <div className="stat-item">
              <span className="stat-number">{user.followers_count}</span>{" "}
              Followers
            </div>
          </div>
        </div>

        {!isCurrentUser && (
          <Follow
            followed={user.is_followed}
            userId={user.id}
            isInList={true}
            customElement={
              user.is_followed ? (
                <FontAwesomeIcon icon={faUserCheck} />
              ) : (
                <FontAwesomeIcon icon={faUserPlus} />
              )
            }
            className={"user-action-button"}
            onFollowChange={onFollowChange}
          />
        )}
      </div>
    </div>
  );
};

export default UserRatingItem;